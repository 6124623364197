export const material_type = {
    name: 'material_type',
    params: {
        entityConfig: {
            name: 'material_type',
            QueryBuilder: {
                withUnits: () => ({ join: ['unit', 'unit.dimension'] })
            },
        }
    }
};
