import { Inject, Injectable } from '@angular/core';
import { Money } from '@proman/interfaces/common.interface';
import { Currency } from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { getCurrencies } from '@proman/store/currency/currency.selectors';
import { filter, tap } from '@proman/rxjs-common';

function getMappedByName(data: Currency[]) {
    let mappedData = {};
    for (let item of data) {
        mappedData[item.name] = item;
    }
    return mappedData;
}

function getMappedById(data: Currency[]) {
    let mappedData = {};
    for (let item of data) {
        mappedData[item.id] = item;
    }
    return mappedData;
}

@Injectable({ providedIn: 'root' })
export class CurrenciesService {
    currencies: Currency[];
    base: Currency;
    dataMappedByName: { [key: string]: Currency } = {};
    dataMappedById: { [key: number]: Currency } = {};

    constructor(
        @Inject(Store) private store: Store
    ) {
        this.store.select(getCurrencies)
            .pipe(
                filter((data) => !!data?.length),
                tap((data: Currency[]) => {
                this.currencies = data;
                this.base = data?.find((item) => item.main);
                this.dataMappedByName = getMappedByName(data);
                this.dataMappedById = getMappedById(data);
            }))
            .subscribe();

    }

    findByName = (name: string) => {
        return this.dataMappedByName[name];
    };

    convert = (amount: string|number, _to: any, _from: any) => {
        let to;
        let from;

        if (!isNaN(parseInt(_to))) {
            to = this.dataMappedById[_to.id];

        } else  {
            to = _to ? this.findByName(_to) : this.base;

        }

        if (!isNaN(parseInt(_from))) {
            from = this.dataMappedById[_from.id];

        } else {
            from = _from ? this.findByName(_from) : this.base;

        }

        amount = +amount || 0;

        if (!to || amount === 0) {
            return amount;
        }

        return parseFloat((+amount / from?.ratio * to.ratio).toFixed(2));
    };

    getZeroPrice(currency?: string): Money {
        return { currency: currency ?? this.base?.name, amount: '0' };
    }

    getSumMoney(amount?: number): Money {
        return {amount: (amount ?? 0.0).toString() ?? '0', currency: this.base?.name };
    }

}
