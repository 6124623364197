import { EntityInterface } from '@proman/services/entity.service';

export const currency = {
    name: 'currency',
    params: {
        entityConfig: {
            name: 'currency',
            post: [ 'setMain', 'recalculate' ],
            get: [ 'currencySources', 'getCurrencyRate' ]
        }
    }
};

export interface CurrencyEntityInterface extends EntityInterface {
    currencySources: () => Promise<unknown[]>;
    getCurrencyRate: (data: { date: string; name: string }) => Promise<number>;
}
