import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UtilsData } from './utils.reducer';

export const utilsKey: string = 'utils';

export const selectUtilsState = createFeatureSelector<UtilsData>(utilsKey);

export const getDataUpdateTriggerTimeStamp = createSelector(
    selectUtilsState, (state: UtilsData) => state.updateTimeStamp
);

export const getPendingRequests = createSelector(
    selectUtilsState, (state: UtilsData) => state.pendingRequests
);

export const getJsonRequests = createSelector(
    selectUtilsState, (state: UtilsData) => state?.jsonReqs || []
);

export const getInactivityStatus = createSelector(
    selectUtilsState, (state: UtilsData) => state.inactivityStatus
);

export const getActiveTabStatus = createSelector(
    selectUtilsState, (state: UtilsData) => state.activeTabStatus
);
