import { EntityInterface } from '@proman/services/entity.service';

export const printer = {
    name: 'printer',
    params: {
        entityConfig: {
            name: 'printer',
            get: ['print'],
            post: [
                'printProductContainer',
                'printProductContainerProduct',
                'printOperationTemplate',
                'printProduct',
                'addFont',
                'printPosTest',
                'printQuant',
                'printWarehouseLocation',
                'printCodePage',
                'printArticleBarcode',
]
        },
        types: ['Zpl', 'Pos', 'FiscalPrinter']
    }
};

export interface PrinterEntityInterface extends EntityInterface {
    print: (data: {  id: number; zpl: string }) => Promise<any>;
    printProductContainer: (data: {
        printer: number;
        barcode_template: number;
        productContainer: number|number[];
    }) => Promise<any>;
    printOperationTemplate: (data: {
        printer: number;
        barcode_template: number;
        production_operation_id: number;
    }) => Promise<any>;
    printProduct: (data: {
        printer: number;
        barcode_template: number;
        id: number;
    }) => Promise<any>;
    printProductContainerProduct: (data: {
        printer: number;
        barcode_template: number;
        productId: number;
        shipmentContainerId: number;
    }) => Promise<any>;
    printQuant: (data: {
        device: number;
        barcode_template: number;
        quant: number;
        quantity?: string|number;
    }) => Promise<any>;
    printWarehouseLocation: (data: {
        device: number;
        barcode_template: number;
        warehouse_location: number;
    }) => Promise<any>;
    printArticleBarcode: (data: { id: number, template: number, printer: number }) => Promise<any>;

}
