import { EntityInterface } from '../services/entity.service';

export const maintenance = {
    name: 'maintenance',
    params: {
        entityConfig: {
            name: 'maintenance',
            post: [
                'start',
                'end',
                'cancel',
                'addEmployee',
                'removeEmployee',
                'updateBooking'
            ]
        }
    }
};

export interface MaintenanceEntityInterface extends EntityInterface {
    addEmployee: (data: { id: number; employee: number }) => Promise<unknown>;
    removeEmployee: (data: { id: number; booking: number }) => Promise<unknown>;
    start: (data: { id: number }) => Promise<unknown>;
    end: (data: { id: number }) => Promise<unknown>;
    cancel: (data: { id: number }) => Promise<unknown>;
    sign: (data: { id: number }) => Promise<unknown>;
}
