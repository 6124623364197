import { EntityInterface } from '@proman/services/entity.service';

export const entrance_card = {
    name: 'entrance_card',
    params: {
        entityConfig: {
            name: 'entrance_card',
            get: [
                'checkLastEvent',
            ],
        }
    }
};

export interface EntranceCardEntityInterface extends EntityInterface {
    checkLastEvent: (data: { id: number[] }) => Promise<any[]>;
  }
