import { MaterialCategory } from '../interfaces/entity-interfaces';
import { EntityInterface } from '../services/entity.service';

export const material_category = {
    name: 'material_category',
    params: {
        entityConfig: {
            name: 'material_category',
            QueryBuilder: {
                aggregate: () => ({ join: ['parent', 'children', 'materials'] }),
                children: (id: number) => ({ parent: id, directChildren: true })
            },
            attrs: {
                canHaveMaterials: (category: any) => {
                    // check if is leaf
                    return category.right === category.left + 1;
                },
                canHaveChildren: (category: MaterialCategory) => {
                    return category.materials.length === 0;
                }
            },
            uris: {
                queryAll: 'query_all'
            },
            get: [
                'getParentMaterialCategories'
            ],
            post: [
                'removeWithSubCategories',
                'removeWithoutSubCategories'
            ]
        }
    }
};

export interface MaterialCategoryEntityInterface extends EntityInterface {
    canHaveMaterials: (category: MaterialCategory) => boolean;
    canHaveChildren: (category: MaterialCategory) => boolean;
}
