export const login_block = {
    name: 'login_block',
    params: {
        entityConfig: {
            name: 'login_block',
        },
        types: [
            'images',
            'video',
            'about',
            'terms',
            'legal_notice',
            'privacy_policy',
            'contact_us',
            'sustainability',
            'code_of_ethics',
            'shop_slide',
            'shop_newsletter_popup',
            'shop_subscribe_picture',
            'recommendations_for_supervision',
            'shop_maintenance',
            'shop_warranties_and_returns',
            'shop_unsubscribe',
            'shop_title_replacement',
        ]
    }
}
