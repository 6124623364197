export const widget = {
    name: 'widget',
    params: {
        entityConfig: {
            name: 'widget'
        },
        searchFields: ['type'],
        widgetTypes: {
            'newest_orders': {
                isConfigurable: false
            },
            'newest_event_comments': {
                isConfigurable: false
            },
            'workplace': {
                isConfigurable: true
            }
        }
    }
};
