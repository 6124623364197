export const order_proposal_parameter = {
    name: 'order_proposal_parameter',
    params: {
        entityConfig: {
            name: 'order_proposal_parameter',
            QueryBuilder: {
                aggregate: (item: any) => ({
                    'orderProposal.id': item.id,
                    'join': ['parameter', 'children', 'children.parameter'],
                    'sort': { position: 'asc' },
                    'translate': true,
                })
            }
        }
    }
};
