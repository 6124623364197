import { createAction, props } from '@ngrx/store';

export enum UtilsActions {
    TriggerUpdate = `[Utils] Update Trigger`,
    PendingReqIncrease = `[Utils] Pending request ++`,
    PendingReqDecrease = `[Utils] Pending request --`,
    AddJsonRequest = `[Utils] Add JSON req`,
    CompleteJsonRequest = `[Utils] Complete JSON req`,
    SetInactivityTimer = `[Utils] Set Inactivity Timer`,
    SetInactivityStatus = `[Utils] Set Inactivity Status`,
    SetActiveTabStatus = `[Utils] Set Active Tab Status`,
}

export const triggerDataUpdate = createAction(
    UtilsActions.TriggerUpdate,
);

export const pendingReqIncr = createAction(
    UtilsActions.PendingReqIncrease,
);

export const pendingReqDecr = createAction(
    UtilsActions.PendingReqDecrease,
);

export const setJsonRequest = createAction(
    UtilsActions.AddJsonRequest,
    props<{ payload: string }>()
);

export const completeJsonRequest = createAction(
    UtilsActions.CompleteJsonRequest,
    props<{ payload: string }>()
);

export const setInactivityTimer = createAction(
    UtilsActions.SetInactivityTimer,
    props<{ payload: number }>()
);

export const setInactivityStatus = createAction(
    UtilsActions.SetInactivityStatus,
    props<{ payload: boolean }>()
);

export const setActiveTabStatus = createAction(
    UtilsActions.SetActiveTabStatus,
    props<{ payload: boolean }>()
);
