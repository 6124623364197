import { EntityInterface } from '../services/entity.service';
import { GeneralLedgerRecord } from '../interfaces/entity-interfaces';

export const general_ledger_record = {
    name: 'general_ledger_record',
    params: {
        entityConfig: {
            name: 'general_ledger_record',
            post: [
                'updateAllKeys',
                'updateKey'
            ]
        },
    }
};

export interface GeneralLedgerRecordEntityInterface extends EntityInterface<GeneralLedgerRecord> {
    updateAllKeys: (data: { date?: string }) => Promise<unknown>;
    updateKey: (data: any) => Promise<unknown>;
}
