import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrUser } from '../../interfaces/object-interfaces';
import { CurrUserStateType } from '../currency';

export const currUserKey: string = 'currUser';

export const selectCurrUserState = createFeatureSelector<CurrUserStateType>(currUserKey);

export const getCurrUser = createSelector(
    selectCurrUserState,
    (state): CurrUser =>  state.data
);

export const getCurrUserType = createSelector(
    getCurrUser,
    (state: CurrUser) =>  state.type
);

export const getCurrUserEntityFilters = createSelector(
    selectCurrUserState,
    (state: CurrUserStateType) =>  state.entityFilters
);

export const getCurrUserUiPrefs = createSelector(
    selectCurrUserState,
    (state: CurrUserStateType) =>  state.uiPrefs
);

export const getUiPrefsProperty = createSelector(
    selectCurrUserState,
    (state: CurrUserStateType, property: string) =>  !!state.uiPrefs[property]
);

export const getMinimalMenu = createSelector(
    selectCurrUserState,
    (state: CurrUserStateType) =>  state.userMenu
);
