import { EntityInterface } from '@proman/services/entity.service';
import { UserMenu } from '@proman/interfaces/entity-interfaces';

export const user_menu = {
    name: 'user_menu',
    params: {
        entityConfig: {
            name: 'user_menu',
            get: [
                'getGroupings',
            ],
        },
    }
};

export interface UserMenuEntityInterface extends EntityInterface<UserMenu> {
    getGroupings: (data: { user: number }) => Promise<any>;
}
