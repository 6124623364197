import { EntityInterface } from '@proman/services/entity.service';

export const paypal = {
    name: 'paypal',
    params: {
        entityConfig: {
            name: 'paypal',
            post: [
                'savePaypalAccount',
            ]
        }
    }
};

export interface PaypalEntityInterface extends EntityInterface {
    savePaypalAccount: (data: { domain: string; token: string }) => Promise<any>;
}
