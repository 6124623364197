import { Injectable } from '@angular/core';
import { CONFIG } from '../config';
import { PreferencesService } from './preferences.service';
import { AppInitService } from './app-init.service';
import { Entity } from './entity.service';
import { RequestService } from './request.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '../store/curr-user';
import { CurrUser } from '../interfaces/object-interfaces';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { loadLanguage } from '@translations/json-language-loader';

@Injectable({ providedIn: 'root' })
export class LanguageConfigService {
    currentLanguage: string = null;
    currUser: CurrUser;

    constructor(
        private store: Store,
        private Prefs: PreferencesService,
        private Translate: TranslateService,
        private AppInit: AppInitService,
        private Entity: Entity,
        private Request: RequestService,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    loadLanguage = async (lang: string) => {
        if (!this.AppInit.translations[lang]) {
            await loadLanguage(lang)
                .then((response: any) => {
                    this.AppInit.currentLang = lang;
                    this.AppInit.translations[lang] = response;
                    this.Prefs.language(lang);

                    return lang;
                });
        }

        await this.Translate.use(lang)
            .toPromise()
            .then(() => {
                this.currentLanguage = lang;

                moment.updateLocale(this.currentLanguage, {
                    months: this.Translate.instant('_month_names').split(' '),
                    monthsShort: this.Translate.instant('_month_names_min').split(' '),
                    weekdays: this.Translate.instant('_day_names').split(' '),
                    weekdaysShort: this.Translate.instant('_day_names_short').split(' '),
                    weekdaysMin: this.Translate.instant('_day_names_min').split(' ')
                });
            });

        return Promise.resolve(lang);
    };

    async set(language?: string) {

        if (!language) {
            await this
                .get()
                .then((_language: string) => language = _language);

        }

        return Promise.resolve(language)
            .then(this.loadLanguage)
            .then(() => Promise.resolve());
    };

    async get() {
        let currUser = this.currUser;
        let language = currUser && currUser.language || this.Prefs.language();
        let index;

        if (!language) {
            await this.Entity.get({ name: 'public/system_options', getUrl: '' })
                .fetch()
                .then((response: any) => {
                    let language = response && response.defaultLanguage;

                    if (!language) {
                        index = CONFIG.languages.indexOf(CONFIG.defaultLanguage);
                        language = CONFIG.languages[index];

                    }

                });

        }

        if (!language) language = 'en';
        return Promise.resolve(language);
    };

}
