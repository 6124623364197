import { Injectable } from '@angular/core';
import { ACL } from '@proman/services/acl.service';
import { Router } from '@angular/router';
import { hasSubstring, isNumber } from '@proman/utils';
import { PreferencesService } from '@proman/services/preferences.service';
import { Entity } from '@proman/services/entity.service';
import { MenuService } from './menu.service';
import { isArray } from '@proman/utils';
import { CONFIG } from '@proman/config';
import { CursorLoadingService } from '@proman/services/cursor-loading.service';
import { routeMap } from '../proman-route-map';
import { Store } from '@ngrx/store';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getCurrUser } from '@proman/store/curr-user';

@Injectable({
    providedIn: 'root'
})
export class PromanStateService {
    currUser: CurrUser;

    constructor(
        private Prefs: PreferencesService,
        private ACL: ACL,
        private Entity: Entity,
        private Menu: MenuService,
        private Router: Router,
        private CursorLoading: CursorLoadingService,
        private store: Store,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    getRoute(name: any, params?: any): [string, string] {
        let state = routeMap[name];

        if (state) {

            if (isArray(params)) {
                let parts = routeMap[name].split('/');

                for (let item of parts) {
                    if (item.startsWith(':')) state = state.replace(item, params.shift());
                }

            } else if (hasSubstring(state, ':')) {
                let parts = routeMap[name].split('/');
                let routerState = this.Router.routerState.root;
                let item;

                for (item of parts) {
                    if (hasSubstring(item, ':')) {
                        let itemValue;
                        item = item.substr(1);

                        while (routerState.firstChild) {

                            if (routerState.snapshot.params[item]) {
                                itemValue = +routerState.snapshot.params[item];
                                break;

                            } else {
                                routerState = routerState.firstChild;
                            }

                        }

                        if (!itemValue && params && typeof params === 'object' && Object.keys(params).length) {

                            if (params[item]) {
                                itemValue = params[item];
                                delete params[item];

                            }

                        }

                        if (!itemValue && isNumber(params)) {
                            itemValue = params;
                            params = null;
                        }

                        state = state.replace(`:${item}`, itemValue);

                    }

                }

            }

        } else {
            state = name || ''; // when name is undefined go to default
        }

        if (params && typeof params === 'object' && Object.keys(params).length === 1) {
            let key;
            for (key in params) {
                params = params[key];

            }

        }

        if (params && typeof params === 'object' && !Object.keys(params).length) {
            params = null;
        }

        if (hasSubstring(state, ':') && isNumber(params)) {
            state = state.split('/')
                .map((part: string) => {
                    return part.startsWith(':') ? params : part
                })
                .join('/');
        }

        return [state, params];
    }

    to = (name: any, _params?: any, queryParams?: any, newTab: boolean = false) => {
        this.CursorLoading.start();

        let [state, params] = this.getRoute(name, _params);

        if (newTab) {
            window.open(this.generateUrl(state, params, queryParams), '_blank');

        } else {
            (params) ?
                this.Router.navigate([state, params], queryParams ? { queryParams } : undefined) :
                this.Router.navigate([state], queryParams ? { queryParams } : undefined);

        }
    };

    getPath = (name: any, _params?: any, _queryParams?: any): [string[], Object] => {
      let [state, params] = this.getRoute(name, _params);

      const queryParams = _queryParams ? { queryParams: _queryParams } : { };

      state = `/${state}`

      return  [
        (params ? [state, params] : [state]),
        queryParams
      ];
    };

    childStates = (parentName: any): any[] => {
        return [];
    };

    getAllowedState = async () => {
        const currUser = this.currUser;

        const dashboards = currUser ? await this.Entity.get('dashboard').search({ 'owner.id': currUser.person.id, 'sort': { position: 'asc' } }) : [];

        let state;

        if (dashboards?.length) {
            state = dashboards[0].config && JSON.parse(dashboards[0].config).state;

        }

        if (!state) {
            state = this.getCategoryState(this.Menu.items[0] || {});

        }

        return this.to(state);
    };

    go = (reload?: boolean) => {
        let location = '';
        let intendedLocation = this.Prefs.intendedLocation();

        if (intendedLocation && !hasSubstring(intendedLocation, 'login')) { // if 'api/user' fails on login, go to default state
            this.Prefs.intendedLocation(null);
            location = intendedLocation;

        }

        return reload ? window.location.href = location : this.Router.navigate([location || '']);

    };

    getCategoryState = (item: any) => {
        let state;

        if (item.tabs) {

            for (let tab of item.tabs) {

                if (this.isAllowed(tab)) {
                    state = tab.state;
                    break;

                }

            }

        }

        if (item.state && this.isAllowed(item)) {
            state = item.state;
        }

        return state;
    };

    isAllowed = (item: any) => {
        return item.acl ? this.ACL.check(item.acl) : true;
    };

    getUrl(name: string, _params?: any) {
        let [state, params] = this.getRoute(name, _params);

        return params ? `/${state}/${_params}` : `/${state}`;
    }

    generateUrl(state: any, params: any = null, queryParams: any = null) {
        let url = CONFIG.root;

        url += state;

        if (params && !url.endsWith('/')) url += '/';

        if (params) url += params;

        if (queryParams) {
            let queryString = '?';

            for (let key in queryParams) {
                queryString += `${key}=${queryParams[key]}`
            }

            url += queryString;
        }

        return url;
    }

}
