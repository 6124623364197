import { EntityInterface } from '../services/entity.service';
import { ShopTemplateItemEntity } from '../interfaces/entity-interfaces';

export const shop = {
    name: 'shop',
    params: {
        entityConfig: {
            name: 'shop',
            get: [
                'settings',
                'cart',
                'getTemplateItems',
            ],
            post: [
              'newProject',
            ],
            postJson: [
                'review',
            ],
            notApi: true,
        }
    }
};

export interface ShopEntityInterface extends EntityInterface {
    settings: () => Promise<unknown>;
    cart: () => Promise<unknown>;
    review: (data: { article: number; message: string }) => Promise<unknown>;
    newProject: (data: { name: string, phone?: string, email?: string, description?: string, article?: number }) => Promise<unknown>;
    getTemplateItems: () => Promise<ShopTemplateItemEntity[]>;
}

