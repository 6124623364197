import { EntityInterface } from '@proman/services/entity.service';
import { PromanFile } from '@proman/interfaces/entity-interfaces';

export const file = {
    name: 'file',
    params: {
        entityConfig: {
            name: 'file',
            post: ['pdfFromHtml', 'create2',],
            get: ['getFile']
        }
    }
};

export type PromanFileIconType = 'png'|'pdf'|'jpeg'|'icon'|'main'|'raw'|'ocr'|'stream';

export interface FileEntityInterface extends EntityInterface<PromanFile> {
    pdfFromHtml: (data: { html: string }) => Promise<PromanFile>;
    getFile: (data: { hash: string; type: PromanFileIconType }) => Promise<string>;
    create2: (data: { html: string }) => Promise<PromanFile>;
}
