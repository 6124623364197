import { OrderProduct } from '@proman/interfaces/entity-interfaces';

export const production_product = {
    name: 'production_product',
    params: {
        entityConfig: {
            name: 'production_product',
            attrs: {
                fromOrderProduct(product: OrderProduct) {
                    return {
                        productionQuantity: product.quantity,
                        storedQuantity: product.product.storedQuantity,
                        packagingQuantity: product.packagingQuantity,
                        orderProduct: product,
                        parameters: product.product.parameters
                    };
                }
            }
        }
    }
};
