import { createAction, props } from '@ngrx/store';
import { CurrUser } from '../../interfaces/object-interfaces';
import { UserMenu, UserMenuGroup } from '../../interfaces/entity-interfaces';

export enum CurrUserActions {
    SetCurrUser = `[CurrUser] Set Curr User`,
    UserLogout = `[CurrUser] Logout`,
    SetEntityFilters = `[CurrUser] Set EntityFilters`,
    SetUiPrefs = `[CurrUser] Set Ui Prefs`,
    LoadCurrUser = `[CurrUser] Load Curr User`,
    LoadMinimalMenu = `[CurrUser] Load Minimal Menu`,
    SetMinimalMenu = `[CurrUser] Set Minimal Menu`,
}

export const setCurrUser = createAction(
    CurrUserActions.SetCurrUser,
    props<{ payload: CurrUser }>()
);

export const userLogout = createAction(
    CurrUserActions.UserLogout
);

export const setEntityFilters = createAction(
    CurrUserActions.SetEntityFilters,
    props<{ payload: { [key: string]: any } }>()
);

export const setUiPrefs = createAction(
    CurrUserActions.SetUiPrefs,
    props<{ payload: { [key: string]: any } }>()
);

export const loadCurrUser = createAction(
    CurrUserActions.LoadCurrUser,
);

export const loadMinimalMenu = createAction(
    CurrUserActions.LoadMinimalMenu,
);

export const setMinimalMenu = createAction(
    CurrUserActions.SetMinimalMenu,
    props<{ payload: { unassigned: UserMenu[], groups: UserMenuGroup[] } }>()
);
