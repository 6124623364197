import { Action, createReducer, on } from '@ngrx/store';
import {
    completeJsonRequest,
    pendingReqDecr,
    pendingReqIncr, setActiveTabStatus, setInactivityStatus, setInactivityTimer,
    setJsonRequest,
    triggerDataUpdate,
} from './utils.actions';

export interface UtilsData {
    updateTimeStamp: number;
    pendingRequests: number;
    jsonReqs: string[];
    inactivityTimer: number;
    inactivityStatus: boolean;
    activeTabStatus: boolean;
}

export const initialState: UtilsData = {
    updateTimeStamp: 0,
    pendingRequests: 0,
    jsonReqs: [],
    inactivityTimer: 0,
    inactivityStatus: false,
    activeTabStatus: false,
};

export const utilsReducer = createReducer(
    initialState,
    on(triggerDataUpdate, (state: UtilsData, action) => {
        return {  ...state, updateTimeStamp: Date.now() }
    }),
    on(pendingReqIncr, (state: UtilsData, action) => {
        return {  ...state, pendingRequests: state.pendingRequests + 1 }
    }),
    on(pendingReqDecr, (state: UtilsData, action) => {
        return {  ...state, pendingRequests: state.pendingRequests - 1 }
    }),
    on(setJsonRequest, (state: UtilsData, action) => {
        return {  ...state, jsonReqs: [...state.jsonReqs, action.payload] }
    }),
    on(completeJsonRequest, (state: UtilsData, action) => {
        return {  ...state, jsonReqs: [...state.jsonReqs.filter((req) => req !== action.payload)] }
    }),
    on(setInactivityTimer, (state: UtilsData, action) => {
        return { ...state, inactivityTimer: action.payload }
    }),
    on(setInactivityStatus, (state: UtilsData, action) => {
        return { ...state, inactivityStatus: action.payload }
    }),
    on(setActiveTabStatus, (state: UtilsData, action) => {
        return { ...state, activeTabStatus: action.payload }
    }),
);

export function reducer(state: UtilsData, action: Action): UtilsData {
    return utilsReducer(state, action);
}
