import { EntityInterface } from '@proman/services/entity.service';

export const device = {
    name: 'device',
    params: {
        entityConfig: {
            name: 'device',
            post: [
                'addFont'
            ],
        }
    }
};

export interface DeviceEntityInterface extends EntityInterface {
    addFont: (data: { id: number }) => Promise<any>;
}
