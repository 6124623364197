import { EntityInterface } from '../services/entity.service';
import { LooseRelation } from '../interfaces/entity-interfaces';

export const loose_relation = {
    name: 'loose_relation',
    params: {
        entityConfig: {
            name: 'loose_relation',
            get: ['getRelations']
        }
    }
};

export interface LooseRelationEntityInterface extends EntityInterface<LooseRelation> {
    getRelations: (data: { tableName: string; entityId: string|number }) => Promise<LooseRelation[]>;
}
