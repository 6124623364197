export const production_parameter = {
    name: 'production_parameter',
    params: {
        entityConfig: {
            name: 'production_parameter',
            QueryBuilder: {
                aggregate: (production: any) => {
                    return {
                        'production.id': production.id,
                        'join': [
                            'parameter',
                            'articleProductionParameter',
                            'children',
                            'children.parameter'
                        ],
                        'sort': { position: 'asc' }
                    };
                }
            }
        }
    }
};
