import { EntityInterface } from '../services/entity.service';

export const public_container_status = {
    name: 'public/container_status',
    params: {
        entityConfig: {
            name: 'public',
            post: ['isValidEmail', 'isValidDomain', 'createContainer']
        }
    }
};

export interface PublicContainerStatusEntityInterface extends EntityInterface {
    checkHost: (data: { host: string }) => Promise<{ host: string; domain: string; dnsExist: boolean }>;
    isValidDomain: (data: { host: string, domain: string }) => Promise<{ valid: boolean; message: string}>;
    isValidEmail: (data: { email: string }) => Promise<{ valid: boolean; message: string}>;
    createContainer: (data: { name: string; domain: string; email: string, language: string, type:string, phone:string }) => Promise<any>;

}
