import { EntityInterface } from '@proman/services/entity.service';

export const listener_subscriber = {
    name: 'listener_subscriber',
    params: {
        entityConfig: {
            name: 'listener_subscriber',
            post: ['subscribeToTable', 'unsubscribeFromTable'],
            get: ['isSubscribedToTable']
        }

    }
};

export interface RssParams {
    table_name: string;
    entity_id: number;
}

export interface ListenerSubscriberEntityInterface extends EntityInterface {
    isSubscribedToTable: (data: RssParams) => Promise<{
        subscribed: boolean;
    }>;
    subscribeToTable: (data: RssParams) => Promise<any>;
    unsubscribeFromTable: (data: RssParams) => Promise<any>;
}
