import { EntityInterface } from '@proman/services/entity.service';
import { ArticleTest } from '@proman/interfaces/entity-interfaces';

export const article_test = {
    name: 'article_test',
    params: {
        entityConfig: {
            name: 'article_test',
            get: ['getTestStatus'],
            post: ['addParameter']
        }
    }
};

export interface ArticleTestEntityInterface extends EntityInterface<ArticleTest> {
    addParameter: (data: { id: number; parameter: number }) => Promise<void>;
    getTestStatus: () => Promise<string[]>;
}
