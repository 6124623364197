import { Injectable } from '@angular/core';

export const COOKIE_AUTHORIZATION = 'authorization';
export const COOKIE_SHOP_PROPOSAL = 'shop_proposal';

@Injectable({ providedIn: 'root' })
export class CookiesService {
    lastCookie: string = document.cookie;

    constructor() {
    }

    set(cname: string, cvalue: string, exdays: number = 1) {
        let d = new Date();

        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

        let expires = `expires=${d.toUTCString()}`;

        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }

    get(cname: string) {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        let result = '';

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) === 0) {
                result = c.substring(name.length, c.length);
            }

        }

        return result;
    }

    remove(cname: string) {
        this.set(cname, '', -1);
    }

}
