export const agent = {
    name: 'agent',
    params: {
        entityConfig: {
            name: 'agent',
            get: [
                'getMinCommission',
                'getCustomerMaxDiscount',
            ]
        }
    }
};
