import { EntityInterface } from '@proman/services/entity.service';
import { Calendar, ProductionOperation } from '@proman/interfaces/entity-interfaces';

export const calendar = {
    name: 'calendar',
    params: {
        entityConfig: {
            name: 'calendar',
            get: [
                'getAll',
                'getOptions',
                'getEvents',
                'searchEvents'
            ],
            post: [
                'raise',
                'lower',
                'updateOptions',
                'updateStatusColor',
                'deleteHoliday',
            ],
            attrs: {
                WORKGROUPS: 'workgroups',
                WORKPLACES: 'workplaces',
                OPERATIONS: 'operations',
                PERSONAL: 'personal',
                SUBCONTRACTORS: 'subcontractors',
                SPECIALISATIONS: 'specialisations',
                CONSUMERS: 'consumers',
                TYPES: [
                    'WORKGROUPS',
                    'WORKPLACES',
                    'OPERATIONS',
                    'PERSONAL',
                    'SUBCONTRACTORS',
                    'SPECIALISATIONS',
                    'CONSUMERS'
                ]
            }
        }
    }
};

export interface CalendarEntityInterface extends EntityInterface {
    getAll: () => Promise<any[]>;
    getOptions: () => Promise<{
        changedScheduleColor: string;
        endHour: number;
        id: number;
        startHour: number;
        stepMinutes: number; }>;
    searchEvents: (request: { id: number; search: { [key: string]: any }}) => Promise<ProductionOperation[]>;
    getEvents: (request: { id: number; dates: string[] }) => Promise<ProductionOperation[]>;
    WORKGROUPS: 'workgroups';
    WORKPLACES: 'workplaces';
    OPERATIONS: 'operations';
    PERSONAL: 'personal';
    SUBCONTRACTORS: 'subcontractors';
    SPECIALISATIONS: 'specialisations';
    CONSUMERS: 'consumers';
    TYPES: [
        'WORKGROUPS',
        'WORKPLACES',
        'OPERATIONS',
        'PERSONAL',
        'SUBCONTRACTORS',
        'SPECIALISATIONS',
        'CONSUMERS'
    ];
    updateStatusColor: (data: { status: string; color: string }) => Promise<any>;
}

export interface CalendarEntityInterface extends EntityInterface {
    deleteHoliday: (data: { day: number; month: number }) => Promise<unknown>;
}
