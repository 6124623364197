export const article_operation_production_comment = {
    name: 'article_operation_production_comment',
    params: {
        searchFields: [
            'operation.order.name',
            'operation.production.name',
            'operation.production.order.name',
            'text'
        ],
        types: [
            'technology',
            'quality',
            'improvement'
        ]
    }
};
