import { EntityInterface } from '@proman/services/entity.service';
import { Article } from '@proman/interfaces/entity-interfaces';

export const article = {
    name: 'article',
    params: {
        entityConfig: {
            name: 'article',
            post: [
                'importData',
                'updateLinks',
                'copy',
                'createForDevelopment',
                'addArticleProductionParameterChildren',
                'removeArticleProductionParameterChildren',
                'createMultipleOperationsParameters',
                'addLevel',
                'removeLevel',
                'addCategoriesInArticles',
                'removeCategoriesInArticles',
                'importArticleMaterialsData',
                'renewProductsParameters',
            ],
            get: [
                'pdf',
                'getPosList'
            ],
            uris: {
                importArticlesUri: 'import_articles',
                importUrl: 'import',
                importArticleMaterialsUrl: 'import_article_materials'
            },
            QueryBuilder: {
                joinOrderParameters() {
                    return {
                        join: [
                            'orderParameters',
                            'orderParameters.parameter'
                        ]
                    };
                },
                joinPositionParameters() {
                    return {
                        join: [
                            'productParameters',
                            'productParameters.parameter'
                        ]
                    };
                }
            }
        },
        searchFields: [
            'name',
            'altName',
            'technology.name',
            'description'
        ],
        get: {
            join: [
                'photo',
                'video',
                'developmentProjects',
                'files',
                'productionParameters',
                'productionParameters.parameter',
                'material',
                'vat',
                'formula',
                'timeTags',
                'discountTags',
                'devices',
                'type',
                'packagingQuantityFunction',
                'productNameFunction',
                'productionNameFunction',
                'productionQuantityFunction',
                'orderQuantityFunction',
                'storedQuantityFunction',
                'primeCostFunction',
                'packagingLotFunction',
                'productBarcodeFunction',
                'weightFunction'

            ],
            partialJoin: {
                createdBy: ['name'],
                updatedBy: ['name'],
            },
        }
    }
};

export interface ArticleEntityInterface extends EntityInterface<Article> {
    updateMultiple: (data: any) => Promise<any>;
    addCategoriesInArticles: (data: { articles: number[]; categories: number[] }) => Promise<any>;
    removeCategoriesInArticles: (data: { articles: number[]; categories: number[] }) => Promise<any>;
    createMultipleOperationsParameters: (data: any) => Promise<any>;
    importArticleMaterialsData: (data: any) => Promise<any>;
    renewProductsParameters: (data: { id: number }) => Promise<any>;
    importUrl: 'import';
    importArticleMaterialsUrl: 'import_article_materials';
    getPosList: (data: { stored?: number }) => Promise<Article[]>;
}
