import { EntityInterface } from '@proman/services/entity.service';

export const entrance = {
    name: 'entrance',
    params: {
        entityConfig: {
            name: 'public/entrance',
            post: [
                'button',
            ],
        }
    }
};

export interface EntranceEntityInterface extends EntityInterface {
    button: (data: { entranceCardNumber: string; direction: string }) => Promise<any[]>;
}
