import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CurrUserActions, setCurrUser, setMinimalMenu } from './curr-user.actions';
import { switchMap, tap } from '@proman/rxjs-common';
import { CurrUser } from '../../interfaces/object-interfaces';
import { RequestService } from '../../services/request.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from './curr-user.selectors';
import { Entity } from '../../services/entity.service';
import { isDefinedNotNull } from "@proman/utils";

@Injectable()
export class CurrUserEffects {

    loadCurrUser$ = createEffect(() => this.actions$
        .pipe(
            ofType(CurrUserActions.LoadCurrUser),
            tap(() => {
                this.Request
                    .loadUser()
                    .then(async (response: CurrUser) => {
                        await this.store.dispatch(setCurrUser({ payload: response }));
                    });
            }),
        ), { dispatch: false });

    loadMinimalMenu$ = createEffect(() => this.actions$
        .pipe(
            ofType(CurrUserActions.LoadMinimalMenu),
            switchMap(() => this.store.select(getCurrUser)),
            tap((currUser) => {

                if (currUser?.type === 'employee') {
                    this.Entity.get('user_menu').getGroupings({ user: currUser.id })
                        .then((response) => {
                          if (isDefinedNotNull(response)) this.store.dispatch(setMinimalMenu({ payload: response }));
                        });
                }

            }),
        ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private Request: RequestService,
        private Entity: Entity,
        private store: Store,
    ) {

    }

}
