import { EntityInterface } from '@proman/services/entity.service';
import { Parameter } from '@proman/interfaces/entity-interfaces';

export const parameter = {
    name: 'parameter',
    params: {
        entityConfig: {
            name: 'parameter',
            post: ['addParent', 'removeParent'],
            attrs: {
                NUMBER: 'number',
                STRING: 'string',
                TEXT: 'text',
                BOOLEAN: 'boolean',
                LIST: 'list',
                DROPDOWN: 'dropdown',
                DATETIME: 'datetime',
                DURATION: 'duration',
                PARAMETER_GROUP: 'parameter_group',
                WORKGROUP: 'workgroup',
                MATERIAL: 'material',
                MATERIAL_CATEGORY: 'material_category',
                SUBCONTRACTORS: 'subcontractors',
                PRICE: 'price',
                WEIGHT: 'weight',
            },
        },
        searchFields: ['alias', 'name'],
        types: [
            {
                id: 'number',
                name: 'number'
            },
            {
                id: 'string',
                name: 'text'
            },
            {
                id: 'boolean',
                name: 'boolean'
            },
            {
                id: 'list',
                name: 'list'
            },
            {
                id: 'dropdown',
                name: 'dropdown'
            },
            {
                id: 'datetime',
                name: 'datetime'
            },
            {
                id: 'duration',
                name: 'duration'
            },
            {
                id: 'parameter_group',
                name: 'parameter_group'
            },
            {
                id: 'workgroup',
                name: 'resources'
            },
            {
                id: 'material',
                name: 'material'
            },
            {
                id: 'material_category',
                name: 'material_category',
            },
            {
                id: 'subcontractors',
                name: 'subcontractors'
            },
            {
                id: 'price',
                name: 'price'
            },
            {
                id: 'weight',
                name: 'weight'
            },
        ],
        contexts: [
            'order',
            'product',
            'production',
            'workplace',
            'operation',
            'material_format',
            'test',
            'global',
        ],
    }
};

export interface ParameterEntityInterface extends EntityInterface<Parameter> {
    NUMBER: 'number';
    STRING: 'string';
    TEXT: 'text';
    BOOLEAN: 'boolean';
    LIST: 'list';
    DROPDOWN: 'dropdown';
    DATETIME: 'datetime';
    DURATION: 'duration';
    PARAMETER_GROUP: 'parameter_group';
    WORKGROUP: 'workgroup';
    MATERIAL: 'material';
    MATERIAL_CATEGORY: 'material_category';
    SUBCONTRACTORS: 'subcontractors';
    PRICE: 'price';
    WEIGHT: 'weight',
    addParent: (data: { id: number; parentId: number }) => Promise<void>;
    removeParent: (data: { id: number; parentId: number }) => Promise<void>;
}
