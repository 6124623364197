import { EntityInterface } from '@proman/services/entity.service';
import {
    BankTransaction,
    Employee,
    Salary,
    GeneralLedgerRecord,
    Tax
} from '@proman/interfaces/entity-interfaces';
import {Money, text} from '@proman/interfaces/common.interface';

export const tax = {
    name: 'tax',
    params: {
        entityConfig: {
            name: 'tax',
            get: [
                'getTaxTypesList',
            ],
        },
        get: {
            select: [
                'id',
                'name',
                'period',
                'confirmed',
                'isPaid',
                'comments',
                'date',
                'sum',
            ],
            join: [
                'taxType',
                'payments',
                'bankTransaction',
                'recordDbit',
                'recordCrdt',
            ]

        },
        searchFields: [
            'name',
            'specialisations.name',
        ],
    }
};

export interface TaxEntityInterface extends EntityInterface<Tax> {
    getTaxTypesList: () => Promise<string[]>;

}
