import { EntityInterface } from '../services/entity.service';

export const v2auth = {
    name: 'v2-auth',
    params: {
        entityConfig: {
            name: 'v2/auth',
            notApi: true,
            post: [
                'register',
                'reset'
            ]
        }
    }
};

export interface V2AuthEntityInterface extends EntityInterface {
    register: (data: {
        email: string;
        tos: number;
        marketing: number;
        register: unknown;
        address?: any;
    }) => Promise<any>;
    reset: (data: { email: string }) => Promise<any>;
}
