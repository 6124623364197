export const sale_event = {
    name: 'sale_event',
    params: {
        searchFields: ['name', 'id', 'participants.name', 'customer.name', 'customer.alias', 'saleEventType.name', 'summary', 'resume'],
        entityConfig: {
            name: 'sale_event',
            post: ['copy']
        }
    }
};
