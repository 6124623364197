export const order_template = {
    name: 'order_template',
    params: {
        entityConfig: {
            name: 'order_template',
            QueryBuilder: {
                aggregate: () => ({
                    join: [
                        'article',
                        'productParameters',
                        'productParameters.parameter',
                    ]
                })
            }
        }
    }
};
