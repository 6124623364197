import { EntityInterface } from '@proman/services/entity.service';

export const company_account = {
    name: 'company_account',
    params: {
        entityConfig: {
            name: 'company_account',
            post: ['bankCamtImport', 'matchTransactions', 'customerVatCheck']
        }
    }
};

export interface CompanyAccountEntityInterface extends EntityInterface {
    bankCamtImport: (data: { files: number }) => Promise<any>;
    matchTransactions: (data: { ids: number[] }) => Promise<any>;
    customerVatCheck: (data: { customer_id: number }) => Promise<{ valid: boolean }>;
}
