export const article_material = {
    name: 'article_material',
    params: {
        entityConfig: {
            name: 'article_material',
            finalCoefficient: 'finalCoefficient'
        },
        getArticleMaterials: (articleId: number) => ({
            'article.id': articleId,
            'join': ['material', 'quantityFunction', 'material.materialType', 'quant', 'quant.quantFormats'],
            'sort': { position: 'asc' }
        }),
    }
};
