import { EntityInterface } from '../services/entity.service';
import { MaterialQuant, MaterialQuantFormat } from '../interfaces/entity-interfaces';
import {dateTime, Money} from '../interfaces/common.interface';
import { TableField } from '@proman/interfaces/object-interfaces';
import { isDefined } from '@proman/utils';

export const material_quant = {
    name: 'material_quant',
    params: {
        entityConfig: {
            name: 'material_quant',
            post: [
                'updateStock',
                'purchase',
                'stock',
                'writeOff',
                'damaged',
                'moveToProduction',
                'moveToWarehouse',
                'sendToProcessing',
                'receiveFromProcessing',
                'writeOff',
                'merge',
                'reserve',
                'reserveForOrder',
                'returnFromProduction',
                'returnToSupplier',
                'mergePurchase',
                'createReservationPurchase',
                'createPaymentsPurchase',
                'reserveManyForOrder',
                'confirmInventorization',
                'confirmInventorizationByDate',
                'barcode',
                'splitQuant',
                'quarantine',
            ],
            attrs: {
                TYPE_ARRIVED: 'arrived',
                TYPE_PURCHASE: 'purchase',
                TYPE_STOCK: 'stock',
                TYPE_PRODUCTION: 'production',
                TYPE_RESERVE: 'reserve',
                TYPE_PROCESSING: 'processing',
                TYPE_SALE: 'sale',
                TYPE_STOCK_IN: 'stock_in',
                TYPE_STOCK_OUT: 'stock_out',
                TYPE_RESTOCK: 'restock',
                TYPE_RETURN: 'return',
                TYPE_RESERVE_IN: 'reserve_in',
                TYPE_RESERVE_OUT: 'reserve_out',
                TYPE_PRODUCTION_IN: 'to_production',
                TYPE_PRODUCTION_OUT: 'from_production',
                TYPE_CONSUMED: 'consumed',
                TYPE_WRITE_OFF: 'write_off',
                TYPE_PROCESSING_IN: 'processing_in',
                TYPE_PROCESSING_OUT: 'processing_out',
                TYPE_EDIT: 'edit',
                TYPE_DAMAGED: 'damaged',
                TYPE_QUARANTINE: 'quarantine',
                TYPE_REJECTED: 'rejected',
                TYPE_INVENTORIZATION: 'inventorization',
            },

        },
        searchFields: [
            'name',
            'material.name',
            'order.name',
            'production.name'
        ]
    },
    utils: {
        getFormat(quant: MaterialQuant) {
            if (!quant || !quant.quantFormats) return '';

            const result = quant.lotNumber ? `${quant.lotNumber} | ` : '';

            const isMaterialTypeTypeformat = (format: MaterialQuantFormat) => {
                let result;
                try {
                    if (isDefined((format.materialFormat as any).materialTypeFormat.formatInName)) {
                        result = (format.materialFormat as any).materialTypeFormat.formatInName;
                    }
                }  catch (e) {
                    result = null;
                }

                return result;
            };

            const dimensions = quant.quantFormats
                .filter((quantFormat) => isMaterialTypeTypeformat(quantFormat) !== false)
                .map((quantFormat: MaterialQuantFormat) => !isNaN(+quantFormat.value) ? +quantFormat.value : quantFormat.value)
                .join('x');

            return result + (dimensions || '');
        },
        getQuantTestStatusTableField: (translateMethod: (key: string) => string): TableField => ({
            name: 'test_status',
            key: 'testStatus',
            hidden: true,
            getValue: (quant: MaterialQuant) => {
                const options: any = { not_tested: { color: '#2196f3' }, test_pass: { color: '#00C853' }, test_fail: { color: '#FF5252' }  };

                if (!quant.testStatus) quant.testStatus = 'not_tested';

                const status = quant.testStatus;

                return options[status] ? `<span style="color: ${options[status].color}; text-transform: uppercase;">${translateMethod(status)}</span>` : `${status}`;
            },
            formatter: 'compile',
        }),
    }
};

export interface MaterialQuantEntityInterface extends EntityInterface<MaterialQuant> {
    TYPE_ARRIVED: 'arrived';
    TYPE_PURCHASE: 'purchase';
    TYPE_STOCK: 'stock';
    TYPE_PRODUCTION: 'production';
    TYPE_RESERVE: 'reserve';
    TYPE_PROCESSING: 'processing';
    TYPE_SALE: 'sale';
    TYPE_STOCK_IN: 'stock_in';
    TYPE_STOCK_OUT: 'stock_out';
    TYPE_RESTOCK: 'restock';
    TYPE_RETURN: 'return';
    TYPE_RESERVE_IN: 'reserve_in';
    TYPE_RESERVE_OUT: 'reserve_out';
    TYPE_PRODUCTION_IN: 'to_production';
    TYPE_PRODUCTION_OUT: 'from_production';
    TYPE_CONSUMED: 'consumed';
    TYPE_WRITE_OFF: 'write_off';
    TYPE_PROCESSING_IN: 'processing_in';
    TYPE_PROCESSING_OUT: 'processing_out';
    TYPE_EDIT: 'edit';
    TYPE_DAMAGED: 'damaged';
    TYPE_QUARANTINE: 'quarantine';
    TYPE_REJECTED: 'rejected';
    TYPE_INVENTORIZATION: 'inventorization';
    TYPE_INVENTORIZATION_BY_DATE: 'inventorization_by_date';

    writeOff: (data: {
        source?: number;
        quantity?: number|string;
        warehouseLocation?: number;
        comments?: string;
        quants?: number[];
    }) => Promise<any>;

    merge: (data: {
        source?: number;
        quants?: number[];
        comments?: string;
    }) => Promise<any>;

    barcode: (data: {
        quant: number;
        barcodeValue: string;
    }) => Promise<any>;

    splitQuant: (data: {
        id: number; quantity?: string|number;
        warehouseLocation?: number;
    }) => Promise<any>;

    updateStock: (data: {
        id: number;
        weight: number|string;
        quantity: number|string;
        unitPrice: Money;
        comments: string;
        lotNumber: string;
        locationNotes: string;
    }) => Promise<any>;

    moveToProduction: (data: {
        order: number;
        production?: number;
        material: number;
        quant?: number;
        source?: number;
        quantity: number|string;
        unit?: string;
        productionMaterial?: number;
    }) => Promise<any>;

    moveToWarehouse: (data: {
        source: number;
        quantity: number|string;
        comments?: number;
        warehouse: number;
    }) => Promise<any>;

    reserveManyForOrder: (data: {
        order: number;
        quants: Array<{
            quant: number;
            material: number;
            production: number;
            quantity: number|string;
        }>;
    }) => Promise<any>;

    confirmInventorization: (data: {
        inventorization: number;
        quants: Array<{
            quant: number;
            material: number;
            quantity: number|string;
        }>;
    }) => Promise<any>;

    confirmInventorizationByDate: (data: {
        inventorization: number;
        inventorizationDate: dateTime;
        quants: Array<{
            quant: number;
            material: number;
            quantity: number|string;
        }>;
    }) => Promise<any>;

    reserveForOrder: (data: {
        material: number;
        order: number;
        quantity: string | number;
        quant?: number;
        production?: number;
        productionMaterial?: number;
    }) => Promise<any>;

    purchase: (data: {
       items: any[];
    }) => Promise<number[]>;

    stock: (data: any) => Promise<number>;

    reserve: (data: {
        material: number;
        source: number;
        quantity: number|string;
        production: number;
    }) => Promise<any>;

    mergePurchase: (data: {
        ids: number[];
    }) => Promise<any>;

    createPaymentsPurchase: (data: {
        template: number;
        id: number[];
    }) => Promise<any>;

    createReservationPurchase: (data: {
        reservedIds: number[];
    }) => Promise<any>;

    damaged: (data: {
        source: number;
        quantity: number|string;
        comments: string;
        warehouseLocation: number;
    }) => Promise<any>;

    returnFromProduction: (data: {
        source: number;
        quantity: number|string;
        comments?: string;
        warehouseLocation?: number;
        productionMaterial?: number;
    }) => Promise<any>;

    returnToSupplier: (data: {
        material: number;
        quantity: number|string;
        comments: string;
        quant: number;
    }) => Promise<any>;

    receiveFromProcessing: (data: {
        sourceQuant: {
            id: number;
            comments: string;
            quantity?: number|string;
        };
        targetMaterial: number;
        isLocalProcessing: boolean;
        targetQuants?: Array<{
            quantity: number|string;
            unitPrice: Money;
            warehouseLocation: number;
            quantFormats: Array<{
                materialFormat: number;
                value: string|number;
            }>;
        }>;
    }) => Promise<any>;

    sendToProcessing: (data: {
        source: number;
        quantity: string|number;
        comments: string;
        subcontractor: number;
        order: number;
    }) => Promise<any>;

    quarantine: (data: {
        source: number;
    }) => Promise<any>;

}
