import { EntityInterface } from '@proman/services/entity.service';
import { Production, ProductionRisk } from '@proman/interfaces/entity-interfaces';
import { ProductionPermissions } from '@proman/interfaces/object-interfaces';

interface ProductionPossibleActionsInterface {
    [productionId: number]: ProductionPermissions;
}

export const production = {
    name: 'production',
    params: {
        entityConfig: {
            name: 'production',
            post: [
                'copy',
                'confirm',
                'confirmMultiple',
                'reserve',
                'cancelReservation',
                'start',
                'startMultiple',
                'updateProductionMaterials',
                'raiseLayer',
                'lowerLayer',
                'moveToStore',
                'suspend',
                'importFromRemote',
                'importToExistingFromRemote',
                'retryImport',
                'retryImportToExisting',
                'export',
                'addFiles',
                'addMaterial',
                'importData',
                'readFromFile',
                'finish',
                'finishMultiple',
                'forceFinish',
                'changeProductionResource',
                'getOperationsStatuses',
                'evaluateParameters',
            ],
            get: [
                'possibleActions',
                'getOperationsRisks',
            ],
            uris: {
                IMPORT: 'import',
                importFromFileUri: 'import_from_file',
                importToExistingFromFileUri: 'import_to_existing_from_file',
                readFromFileUri: 'read_from_file'
            },
            attrs: {
                CREATED: 'created',
                CONFIRMED: 'confirmed',
                COPIED: 'copied',
                RESERVED: 'reserved',
                STARTED: 'started',
                COMPLETE: 'complete',
                CANCELED: 'canceled',
                SUSPENDED: 'suspended'
            },
            QueryBuilder: {
                joinProducts() {
                    return {
                        join: [
                            'productionProducts',
                            'productionProducts.orderProduct',
                            'productionProducts.orderProduct.product',
                            'productionProducts.orderProduct.product.photo',
                            'productionProducts.orderProduct.product.parameters',
                            'productionProducts.orderProduct.product.parameters.parameter'
                        ]
                    };
                },
                joinCompletedArticleOperations() {
                    return {
                        join: ['completedArticleOperations', 'completedArticleOperations.operation']
                    };
                }
            },
            defaultSort: { id: 'desc' }
        },
        searchFields: ['id', 'name', 'orders.customerNumber', 'orders.name', 'orders.number', 'quantity'],
        get: { join: [
                'article',
                'article.operations',
                'article.operations.operation',
                'article.formula',
                'tags',
                'completedArticleOperations',
                'completedArticleOperations.operation',
                'type',
                'subtype'
            ] },
        extraParameters: () => {
            return {
                join: [ 'tags' ],
                partialJoin: {
                    article: [ 'id', 'name', 'altName' ],
                },
                sort: { createdAt: 'desc' }
            };
        }
    }
};

export interface ProductionEntityInterface extends EntityInterface<Production> {
    CREATED: 'created';
    COPIED: 'copied';
    CONFIRMED: 'confirmed';
    RESERVED: 'reserved';
    STARTED: 'started';
    COMPLETE: 'complete';
    CANCELED: 'canceled';
    SUSPENDED: 'suspended';
    IMPORT: 'import';
    importFromFileUri: 'import_from_file';
    importToExistingFromFileUri: 'import_to_existing_from_file';
    readFromFileUri: 'read_from_file';
    possibleActions: (data: { id: number[] }) => Promise<ProductionPossibleActionsInterface>;
    start: (data: { id: number }) => Promise<any>;
    startMultiple: (data: { ids: number[] }) => Promise<any>;
    updateProductionMaterials: (data: { id: number }) => void;
    suspend: (data: { id: number }) => Promise<any>;
    finish: (data: { id: number }) => Promise<any>;
    finishMultiple: (data: { ids: number[] }) => Promise<any>;
    forceFinish: (data: { id: number }) => Promise<any>;
    reserve: (data: { id: number }) => Promise<any>;
    cancelReservation: (data: { id: number }) => Promise<any>;
    copy: (data: { id: number }) => Promise<any>;
    confirmMultiple: (data: { ids: number[] }) => Promise<any>;
    addFiles: (data: { id: number; files: number[] }) => Promise<any>;
    addMaterial: (data: any) => Promise<any>;
    changeProductionResource: (data: { id: number; workplaceId: number }) => Promise<any>;
    getOperationsStatuses: (data: { id: number }) => Promise<any>;
    evaluateParameters: (data: { id: number }) => Promise<any>;
    getOperationsRisks: (data: { id: number }) => Promise<ProductionRisk[]>;
    qwert: () => void;
}
