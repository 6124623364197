export const menuBookkeepingUser = [
  {
    type: 'group',
    name: 'sales',
    icon: 'briefcase',
    tabs: [
      {
        name: 'invoices',
        state: 'Invoices',
      },
      {
        name: 'customers',
        state: 'Customers',
      },
      {
        name: 'carriers',
        state: 'Carriers',
      },
    ],
  },
  {
    type: 'group',
    name: 'products',
    icon: 'shelves',
    tabs: [
      {
        name: 'products',
        state: 'Products',
      },
      {
        name: 'services',
        state: 'Services',
      },
      {
        name: 'warehouses',
        state: 'Warehouses',
      },
    ]
  },
  {
    name: 'projects',
    state: 'SaleOpportunities',
    icon: 'folder-open',
  },
  {
    type: 'group',
    name: 'purchases',
    icon: 'coins',
    tabs: [
      {
        name: 'purchases',
        state: 'Purchase',
      },
      {
        name: 'suppliers',
        state: 'Suppliers',
      },
      {
        name: 'carriers',
        state: 'Carriers',
      },
    ],
  },
  {
    type: 'group',
    name: 'staff',
    icon: 'users',
    tabs: [
      {
        name: 'employees',
        state: 'Employees',
        acl: 'employee.display',
      },

      {
        name: 'documents',
        state: 'EmployeesDocuments',
        acl: 'employee.display',
      },
      {
        name: 'tabel_daily',
        state: 'TabelDaily',
      },
      {
        name: 'tabel_monthly',
        state: 'Tabel',
      },
      {
        name: 'tabel_weekly',
        state: 'TabelWeekly',
      },
      {
        name: 'tabel_yearly',
        state: 'TabelWeekly',
      },
      {
        name: 'tabel',
        state: 'TabelTabel',
      },
      {
        name: 'salary',
        state: 'TabelSalary',
        acl: 'employee.show_price',
      },
      {
        name: 'additives',
        state: 'TabelAdditives',
        acl: 'employee.view_all',
      },
      {
        name: 'remnants',
        state: 'TabelRemnants',
        acl: 'employee.view_all',
      },
      {
        name: 'salary_taxes',
        params: 'TabelSalaryTaxes',
        acl: 'employee.view_all',
      },
      {
        name: 'vacations_requests',
        state: 'TabelVacationRequests',
        acl: 'employee.view_all',
      },
    ]
  },
  {
    type: 'group',
    name: 'accounting',
    icon: 'calculator',
    tabs: [
      {
        name: 'taxes',
        state: 'Taxes',
      },
      {
        name: 'assets',
        state: 'AccountingAssets',
      },
      {
        name: 'balance',
        state: 'Balance',
      },
      {
        name: 'payments',
        state: 'AccountingPayments',
      }
    ],
  },
  {
    name: 'settings',
    state: 'AccountProps',
    icon: 'cogs',
  },
]
