export const menuAgent = [
    {
        name: 'orders',
        state: 'Orders',
        icon: 'tasks'
    },
    // {
    //     name: 'agents_tree',
    //     state: 'AgentsTree',
    //     acl: 'agent.view',
    //     icon: 'code-commit'
    // },
    {
        name: 'development',
        state: 'DevelopmentProjects',
        acl: 'development_project.display',
        icon: 'puzzle-piece'
    },
    {
        type: 'group',
        name: 'sale_events',
        icon: 'briefcase',
        tabs: [
            {
                name: 'customers',
                state: 'Customers',
                acl: 'customer.display',
            },
            {
                name: 'projects',
                state: 'SaleOpportunities',
                acl: 'sale_opportunity.display',
            }
        ]
    },
    {
        name: 'invoices',
        state: 'AgentInvoices',
        icon: 'calculator'
    }
];
