import { Injectable, Inject } from '@angular/core';
import { CONFIG } from '../config';
import { resourcesConfig } from '../resources';
import { RequestService } from './request.service';
import { isDefined, sequentialRequests, SequentialRequests, snakeCase } from '../utils';
import { QueryBuilder } from './query-builder';
import { EmployeeContractEntityInterface } from '../resources/employee_contract';
import { TemplateEntityInterface } from '../resources/template';
import { InvoiceEntityInterface } from '../resources/invoice';
import { PurchaseEntityInterface } from '../resources/purchase';
import { EmployeeEntityInterface } from '../resources/employee';
import { DocumentEntityInterface } from '../resources/document';
import { OrderProposalEntityInterface } from '../resources/order_proposal';
import { ProductEntityInterface } from '../resources/product';
import { EmployeeDocumentEntityInterface } from '../resources/employee-document';
import { OrderEntityInterface } from '../resources/order';
import { PrinterEntityInterface } from '../resources/printer';
import { DevelopmentProjectEntityInterface } from '../resources/development-project';
import { AccountCategoryEntityInterface } from '../resources/account_category';
import { ConfirmationEntityInterface } from '../resources/confirmation';
import { ProductionEntityInterface } from '../resources/production';
import { SupplierEntityInterface } from '../resources/supplier';
import { MaterialEntityInterface } from '../resources/material';
import { DeviceEntityInterface } from '../resources/device';
import { CorporateNamespaceEntityInterface } from '../resources/corporate_namespace';

import { select, Store } from '@ngrx/store';
import { take, Observable } from '@proman/rxjs-common';
import { getCurrUserEntityFilters } from '../store/curr-user';
import { MaterialQuantEntityInterface } from '../resources/material_quant';
import { ProductContainerEntityInterface } from '../resources/product_container';
import { WorkplaceEntityInterface } from '../resources/workplace';
import { ProductionOperationEntityInterface } from '../resources/production_operation';
import { ArticleEntityInterface } from '../resources/article';
import { ArticleTestEntityInterface } from '../resources/article_test';
import { ParameterEntityInterface } from '../resources/parameter';
import { OcrEntityInterface } from '../resources/ocr';
import { CurrencyEntityInterface } from '../resources/currency';
import { FileEntityInterface } from '../resources/file';
import { ShipmentEntityInterface } from '../resources/shipment';
import { EshopEntityInterface } from '../resources/eshop';
import { PosAccountEntityInterface } from '../resources/pos_account';
import { UserEntityInterface } from '../resources/user';
import { MaintenanceEntityInterface } from '../resources/maintenance';
import { Customer, OrderConsumer } from '../interfaces/entity-interfaces';
import { ArticleOperationEntityInterface } from '../resources/article_operation';
import { ConsumerBookingEntityInterface } from '../resources/consumer_booking';
import { CalendarEntityInterface } from '../resources/calendar';
import { OrderProposalProductEntityInterface } from '../resources/order_proposal_product';
import { OrderProductEntityInterface } from '../resources/order_product';
import { LooseRelationEntityInterface } from '../resources/loose_relation';
import { EntranceCardEntityInterface } from '../resources/entrance_card';
import { PaymentProviderEntityInterface } from '../resources/payment_provider';
import { ShopEntityInterface } from '../resources/shop';
import { ShopCartEntityInterface } from '../resources/shop-cart';
import { V2AuthEntityInterface } from '../resources/v2-auth';
import { ShipmentOrderEntityInterface } from '../resources/shipment_order';
import { EntranceEntityInterface } from '../resources/entrance';
import { WorkshiftEntityInterface } from '../resources/workshift';
import { StripeEntityInterface } from '../resources/stripe';
import { PaypalEntityInterface } from '../resources/paypal';
import { OnlinePaymentsEntityInterface } from '../resources/online_payments';
import { CreateOnlineMeetingInterface } from '../resources/create_online_meeting';
import { PaymentCouponInterface } from '../resources/payment_coupon';
import { SmartTagProductEntityInterface } from '../resources/smart_tag_product';
import { ServerEntityInterface } from '../resources/server';
import { ContainerEntityInterface } from '../resources/container';
import { MeasurementResultEntityInterface } from '../resources/measurement_result';
import { AccountRegistryEntityInterface } from '../resources/account_registry';
import { FiscalEntityInterface } from '../resources/fiscal';
import { TaxEntityInterface } from '../resources/tax';
import { GeneralLedgerRecordEntityInterface } from '../resources/general_ledger_record';
import { BodyPressurePointEntityInterface } from '../resources/body_pressure_point';
import { LedgerEntityInterface } from '../resources/ledger';
import { params as buildParams } from '../utils-params';
import { UserMenuEntityInterface } from '../resources/user_menu';
import { ListenerSubscriberEntityInterface } from '../resources/listener_subscriber';
import { ShipmentProductEntityInterface } from '../resources/shipment_product';
import { MaterialCategoryEntityInterface } from '../resources/material_category';
import { DiscountTagEntityInterface } from '../resources/discount_tag';
import { PaymentEntityInterface } from '../resources/payment';
import { InventorizationByDateEntityInterface } from '../resources/inventorization-by-date';
import { EntityLogObject } from '../interfaces/object-interfaces';

export type EntityNameType =
    'access_log'|
    'account_category'|
    'account_overtime_rate'|
    'account_registry'|
    'account_registry_payment'|
    'accounting_book'|
    'accounting_report'|
    'action'|
    'agent'|
    'aggregated_table'|
    'amortization'|
    'area'|
    'article'|
    'article_category'|
    'article_child'|
    'article_combo'|
    'article_customer'|
    'article_material'|
    'article_operation'|
    'article_operation_link'|
    'article_operation_production_comment'|
    'article_operation_production_comment_type'|
    'article_operation_workplace'|
    'article_operation_workplace_specialisation'|
    'article_order_parameter'|
    'article_presentation'|
    'article_product_parameter'|
    'article_operation_parameter'|
    'article_test_parameter'|
    'article_production_child_parameter'|
    'article_production_parameter'|
    'article_test'|
    'article_test_type'|
    'article_type'|
    'asset_responsible_person'|
    'asset_type'|
    'assets'|
    'audit_log'|
    'balance'|
    'bank'|
    'bank_account'|
    'bank_transaction'|
    'barcode'|
    'bug_tracker'|
    'board'|
    'board_day'|
    'board_day_item'|
    'board_options'|
    'board_week'|
    'body_pressure_point'|
    'button'|
    'button_listener'|
    'calendar'|
    'calendar_integrations_controller'|
    'create_online_meeting'|
    'camera'|
    'camera_configuration'|
    'card'|
    'carrier'|
    'carrier_employee'|
    'carrier_shipping_method'|
    'chart'|
    'company_account'|
    'company_account_bank_transaction'|
    'company_account_document'|
    'company_connection_request'|
    'confirmation'|
    'config'|
    'consumer_booking'|
    'corporate_namespace'|
    'country'|
    'currency'|
    'currency_history'|
    'custom_event'|
    'CustomTranslation'|
    'customer'|
    'customer_certificate'|
    'customer_claim'|
    'customer_claim_type'|
    'customer_employee'|
    'customer_department'|
    'customer_type'|
    'dashboard'|
    'dashboard_plan'|
    'dashboard_plan_workplace'|
    'department'|
    'defect_act'|
    'device'|
    'development_project'|
    'development_project_stage'|
    'development_project_task'|
    'diagnosis'|
    'discount_tag'|
    'document'|
    'documentation'|
    'dynamic_field'|
    'dynamic_field_value'|
    'dynamic_table'|
    'dynamic_table_column'|
    'dynamic_query'|
    'element'|
    'email_listener'|
    'employee'|
    'employee_booking'|
    'employee_contract'|
    'employee_contract_work_day_type'|
    'employee_contract_work_week_type'|
    'employee_document'|
    'employee_document_read'|
    'salary'|
    'employee_schedule_entry'|
    'employee_workplace'|
    'employee_workshift'|
    'entrance_log'|
    'entrance_card'|
    'entrance'|
    'entrance_reader'|
    'eshop'|
    'event'|
    'event_comment'|
    'event_link'|
    'event_status_color'|
    'exception_log'|
    'expression'|
    'feature'|
    'feature_value'|
    'file'|
    'fiscal_report_log'|
    'formula'|
    'formula_component_new'|
    'gtag'|
    'general_ledger_record'|
    'github_repository'|
    'health_appointment'|
    'health_exercise'|
    'health_patient_exercise_workload'|
    'health_set'|
    'health_template'|
    'holiday'|
    'inventorization'|
    'inventorization_by_date'|
    'inventory_tag'|
    'invoice'|
    'payment'|
    'invoice_record'|
    'ledger'|
    'ledger_types'|
    'listener'|
    'listener_subscriber'|
    'login_block'|
    'login_block_file'|
    'logistics_type'|
    'loose_relation'|
    'loose_relation_type'|
    'loyalty_card'|
    'maintenance'|
    'material'|
    'material_category'|
    'material_format'|
    'material_supplier'|
    'measurement'|
    'measurement_result'|
    'purchase_category'|
    'ocr_invoice_template'|
    'material_quant'|
    'material_quant_format'|
    'material_quant_event'|
    'material_quantity_log'|
    'material_test'|
    'material_type'|
    'material_type_format'|
    'mqtt_device'|
    'mqtt_sensor'|
    'mqtt_sensor_log'|
    'news_subscriber'|
    'notification'|
    'operation'|
    'operation_material_credit_log'|
    'order'|
    'order_confirmation'|
    'order_certificate'|
    'ocr'|
    'ocr_invoice_log'|
    'online_payments'|
    'payments'|
    'order_consumer'|
    'order_import'|
    'order_parameter'|
    'order_product'|
    'order_proposal'|
    'order_proposal_parameter'|
    'order_proposal_product'|
    'order_proposal_product_parameter'|
    'order_subtype'|
    'order_template'|
    'order_type'|
    'patient'|
    'patient_appointment'|
    'paypal'|
    'payment_card'|
    'payment_card_log'|
    'payment_coupon'|
    'payment_terminal'|
    'payment_type'|
    'parameter'|
    'parameter_dropdown_option'|
    'permission'|
    'plan'|
    'periodic_listener'|
    'pending_order'|
    'periodic_maintenance'|
    'person'|
    'person_email'|
    'person_tips_account'|
    'personal_dynamic_table'|
    'pos_account'|
    'printer'|
    'priority'|
    'product'|
    'product_container'|
    'product_event'|
    'product_log'|
    'product_parameter'|
    'production'|
    'production_material'|
    'production_operation'|
    'production_operation_parameter'|
    'production_parameter'|
    'production_product'|
    'production_type'|
    'production_subtype'|
    'public/customer'|
    'public/metadata'|
    'public/registration'|
    'public/consumer_booking'|
    'public/system_options'|
    'purchase'|
    'registration'|
    'report_workplace'|
    'report_employee'|
    'report_specialisation'|
    'report_sales'|
    'report_article'|
    'report_invoices_and_orders'|
    'report_materials'|
    'resource_booking'|
    'resource_booking_parameter'|
    'role'|
    'safety_sign'|
    'sale_event'|
    'sale_event_stage'|
    'sale_event_type'|
    'sale_opportunity'|
    'sale'|
    'sale_opportunity_project_stage'|
    'sale_opportunity_project_type'|
    'shipment'|
    'shipment_container'|
    'shipment_delay_type'|
    'shipment_log'|
    'shipment_product'|
    'shipment_order'|
    'shipment_order_item'|
    'shop'|
    'shop-cart'|
    'shop_template_item'|
    'stripe'|
    'payment_provider'|
    'tag'|
    'tax_types'|
    'tax'|
    'template'|
    'region'|
    'slack'|
    'setting'|
    'specialisation'|
    'sql_connection_configuration'|
    'subcontractor'|
    'subcontractor_employee'|
    'subcontractor_parameter'|
    'subproduct_stock'|
    'supplier'|
    'supplier_contact'|
    'supplier_discount'|
    'supplier_material_format'|
    'system_options'|
    'tabel_lock'|
    'table'|
    'table_listener'|
    'talk_room'|
    'test_child_parameter'|
    'test_parameter'|
    'time_tag'|
    'timeline'|
    'timeline_message'|
    'token_user'|
    'translation'|
    'trip'|
    'unique_name'|
    'unit'|
    'user'|
    'user_menu'|
    'user_menu_group'|
    'v2-auth'|
    'vacations'|
    'vat'|
    'warehouse'|
    'warehouse_location'|
    'workday_exception_reason'|
    'workgroup'|
    'workplace'|
    'workplace_employee'|
    'workplace_parameter'|
    'workplace_tool'|
    'workplace_tool_parameter'|
    'workshift'|
    'workshift_break'|
  // ----------- smarttag entities --------------
    'smart_tag_category'|
    'smart_tag_description'|
    'smart_tag_product'|
    'smart_tag_product_counter'|
    'smart_tag_tag'|
    'smart_tag_tracking'|
    'smart_tag_product_parameter'|
  // ----------- master   entities --------------
    'language'|
    'fiscal'|
    'git_build'|
    'container'|
    'serv'|
    'server_log'|
    'public/container_status'|
    'public/fiscal';

export const getParams = (data: object, entityFilter: object, defaultSort: object = {}) => buildParams({ ...defaultSort, ...data, ...entityFilter });

export interface EntityConfigInterface {
    name: EntityNameType;
    post?: string[];
    get?: string[];
    getUrl?: string;
    uris?: { [key: string]: string };
    attrs?: { [key: string]: string };
    QueryBuilder?: any;
    [key: string]: unknown;
}

export interface EntityInterface<T = any> {
    search: (data?: any) => Promise<T[]>;
    list: (data?: any) => Promise<T[]>;
    loadByParts: (data?: Record<string, any>, limit?: number) => Observable<T[]>;
    get: (data?: any) => Promise<T>;
    getTypes: () => Promise<any>;
    undelete: (data: { id: number }) => Promise<any>;
    fetch: () => Promise<any>;
    reposition: (data: { positionId: number; positionAt: number; [key: string]: string|number|number[] }) => Promise<any>;
    replica: (data: any) => Promise<any>;
    log: (data: any) => Promise<EntityLogObject>;
    create: (data?: any) => Promise<{ data: number }>;
    createReport: (data?: any) => Promise<{ data: number }>;
    update: (data: { id: number|number[]; [key: string]: any }) => Promise<any>;
    refresh: (data: { id: number|number[]; [key: string]: any }) => Promise<any>;
    recalculate: (data: { id: number|number[]; [key: string]: any }) => Promise<any>;
    productParameterUpdate: (data: { id: number|number[]; [key: string]: any }) => Promise<any>;
    confirm: (data: { id: number|number[]; [key: string]: any }) => Promise<any>;
    updateMultiple: (data: any) => Promise<any>;
    remove: (data: any) => Promise<any>;
    add: (data: any) => Promise<any>;
    copy: (data: any) => Promise<any>;
    addAssociation: (data: any) => Promise<any>;
    sendNotification: (data: { id: number|number[]; [key: string]: any }) => Promise<any>;
    removeAssociation: (data: any) => Promise<any>;
    postRequest: (endpoint: string, data: any) => Promise<any>;
    customizedRequest: (endpoint: string, data?: any) => Promise<any>;
    customizedPost: (endpoint: string, data?: any) => Promise<any>;
    postItem: (endpoint: string, name: string, data: any) => Promise<any>;
    postName: (uriName: string) => ((data: any) => Promise<any>);
    post: (uriName: string) => ((data: any) => Promise<any>);
    QB: any;
    root: string;
    autocompleteProperties?: string[];
    updateAssociations: (object: any, oldObject: any, field: string) => Promise<void>;
}

class EntityInstance {
    config: any;
    root: any;
    autocompleteProperties: any;
    defaultSort: object;
    getUrl: any;
    QB: QueryBuilder;
    fetchPromise: any;
    data: any;
    entityFilter: { [key: string]: any } = {};

    constructor(
        private entityConfig: any,
        private Request: RequestService,
        private store: Store
    ) {
        let root = `${entityConfig.notApi ? CONFIG.root : CONFIG.api}${entityConfig.name}/`;

        this.config = { endpoint: entityConfig.name };
        this.root = root;
        this.autocompleteProperties = typeof entityConfig.autocompleteProperties === 'undefined' ? ['name'] : entityConfig.autocompleteProperties;
        if (entityConfig.defaultSort) {
            this.defaultSort = { sort: entityConfig.defaultSort };
        }

        if (typeof entityConfig.getUrl !== 'undefined') {
            this.getUrl = `${CONFIG.api}${entityConfig.name}`;

            if (entityConfig.getUrl !== '') {
                this.getUrl += `/${entityConfig.getUrl}`;

            }

        } else {
            this.getUrl = root + 'get';

        }

        if (entityConfig.get) {
            for (let getName of entityConfig.get) {
                let uriName = snakeCase(getName);
                this[getName] = this.getName(uriName);

            }

        }

        if (entityConfig.post) {
            for (let postName of entityConfig.post) {
                let uriName = snakeCase(postName);
                this[postName] = this.postName(uriName);

            }

        }

        if (entityConfig.postJson) {
            for (let postName of entityConfig.postJson) {
                let uriName = snakeCase(postName);
                this[postName] = this.postJson(uriName);

            }

        }

        if (entityConfig.uris) {
            for (let prop in entityConfig.uris) {
                this[prop] = root + entityConfig.uris[prop] + '/';
            }

        }

        if (entityConfig.attrs) {
            for (let attr in entityConfig.attrs) {
                this[attr] = entityConfig.attrs[attr];
            }

        }

        this.store.pipe(
                select(getCurrUserEntityFilters),
                take(1)
            )
            .subscribe((filters) => {
                if (filters[entityConfig.name]) {
                    this.entityFilter = filters[entityConfig.name];
                }
        })

        if (isDefined((entityConfig.QueryBuilder))) {
            this.QB = new QueryBuilder(this, entityConfig.QueryBuilder);
        }
    }

    getName(uriName: any) {
        return (...args: any[]) => {
            let arg: any;

            let uriSuffix = '';

            if (args.length > 1) {
                uriSuffix = args.join('/');

            } else if (args.length === 1) {
                arg = args[0];

                if (typeof arg === 'object') {
                    uriSuffix = '?' + buildParams(arg);

                } else {
                    uriSuffix = '/' + arg;

                }

            }

            return this.send(this.root + uriName + uriSuffix);
        };
    }

    private send(url: any) {
        return this.Request.get(url)
            .catch(() => {});
    }

    postName(uriName: any) {
        return (data: any) => {
            return this.Request.post(this.root + uriName, data);
        };
    }

    post(uriName: any) {
        return (data: any) => {
            return this.Request.post(CONFIG.api + uriName, data);
        };
    }

    postJson(uriName: any) {
        return (data: any) => {
            return this.Request.postJson(this.root + uriName, data);
        };
    }

    get(data: any = {}) {
        let params = buildParams(data || {});
        let paramsOut = '';

        if (params) paramsOut = '?' + params;

        return this.Request.get(this.getUrl + paramsOut)
            .catch(() => {});
    }

    fetch() {
        if (this.fetchPromise) {
            return this.fetchPromise;

        } else {
            return this.fetchPromise = this.get(null)
                .then((response: any) => {
                    this.data = response;
                    this.fetchPromise = null;

                    return this.data;
                })
                .catch((): any => {
                    this.fetchPromise = null;
                });
        }

    }

    search = (data: any = {}) => {
        return this.Request.get(`${this.root}search` + '?' + getParams(data, this.entityFilter, this.defaultSort))
            .catch(() => {});
    };

    list = (data: any = {}) => {
        let params = '';
        if (data.group) {
            params = data.group;
            if (data.name) params = `${params}/${data.name}`;
        }
        let url = `${this.root}list`;
        if (params.length > 0) url += '/' + params

        return this.Request.get(url)
            .catch(() => {});
    };

    loadByParts = <T>(request: Record<string, any> = {}, size = 100) => {
        return new Observable<T>((obs) => {
            this.search({ ...request, count: true })
                .then((totalResponse: { count: number }) => {
                    const iters = Math.ceil(totalResponse.count / size);

                    const requests: SequentialRequests = [];

                    for (let i = 0; i < iters; i++) {
                        requests.push(
                            () => this.search({ ...request, limit: size, offset: size * i }).then((response) => obs.next(response))
                        )
                    }

                    sequentialRequests(requests).then(() => obs.complete());
            });
        });
    }

    getTypes = () => {
        return this.Request.get(`${this.root}get_types`)
            .catch(() => {});
    };

    replica = (data: any = {}) => {
        return this.Request.get(`${this.root}replica` + '?' + getParams(data, this.entityFilter))
            .catch(() => {});
    };

    log = (data: any) => {
        return this.Request.get(`${this.root}log` + '?' + getParams(data, this.entityFilter))
            .catch(() => {});
    };

    create(data: any = {}) {
        return this.Request.post(this.root + 'create', data);
    }

    createReport(data: any = {}) {
        return this.Request.post(this.root + 'create_report', data);
    }

    refresh(data: any = {}) {
        return this.Request.post(this.root + 'refresh', data);
    }

    undelete(data: { id: number }) {
        return this.Request.post(this.root + 'undelete', data);
    }

    remove(data: any) {
        return this.Request.post(this.root + 'remove', data);
    }

    add(data: any) {
        return this.Request.post(this.root + 'add', data);
    }

    copy(data: any) {
        return this.Request.post(this.root + 'copy', data);
    }

    reposition(data: { positionId: number; positionAt: number; [key: string]: string|number }) {
        // backend does not take positionAt: 0
        return this.Request.post(this.root + 'reposition', { ...data, positionAt: data.positionAt + 1 });
    }

    update(values: any) {
        return this.Request.post(this.root + 'update', values);
    }

    recalculate(values: any) {
        return this.Request.post(this.root + 'recalculate', values);
    }

    productParameterUpdate(values: any) {
        return this.Request.post(this.root + 'product_parameter_update', values);
    }

    confirm(values: any) {
        return this.Request.post(this.root + 'confirm', values);
    }

    updateMultiple(values: any) {
        return this.Request.post(this.root + 'update_multiple', values);
    }

    addAssociation(data: { id: number|number[]; [key: string]: any }) {
        return this.Request.post(this.root + 'add_association', data);
    }

    sendNotification(data: { id: number|number[]; [key: string]: any }) {
        return this.Request.post(this.root + 'send_notification', data);
    }

    removeAssociation(data: any) {
        return this.Request.post(this.root + 'remove_association', data);
    }

    postRequest(endpoint: any, params: any) {
        return this.Request.post(CONFIG.api + this.entityConfig.name + '/' + endpoint, params);
    }

    postItem(id: any, name: any, data: any) {
        return this.Request.post(this.root + id + '/' + name, data);
    }

    customizedRequest(request: any) {
        let requestOut = CONFIG.api + this.entityConfig.name + (request ? ('/' + request) : '');
        return this.Request.get(requestOut);
    };

    customizedPost(url: string, data: any) {
        return this.Request.post(`${this.root}${url}`, data);
    };

    calculate(data: any) {
        return this.Request.post(this.root + 'calculate', data);
    }

    evaluateParameters(data: any) {
        return this.Request.post(this.root + 'evaluate_parameters', data);
    }
}

@Injectable({ providedIn: 'root' })
export class Entity {
    public resourcesConfig: any = resourcesConfig;

    constructor(
        private Request: RequestService,
        @Inject(Store) private store: Store,
    ) {}

    // @ts-ignore
    get(config: string): EntityInterface<any>;
    get(config: 'account_category'): AccountCategoryEntityInterface;
    get(config: 'general_ledger_record'): GeneralLedgerRecordEntityInterface;
    get(config: 'account_registry'): AccountRegistryEntityInterface;
    get(config: 'article'): ArticleEntityInterface;
    get(config: 'article_operation'): ArticleOperationEntityInterface;
    get(config: 'article_test'): ArticleTestEntityInterface;
    get(config: 'body_pressure_point'): BodyPressurePointEntityInterface;
    get(config: 'confirmation'): ConfirmationEntityInterface;
    get(config: 'create_online_meeting'): CreateOnlineMeetingInterface;
    get(config: 'currency'): CurrencyEntityInterface;
    get(config: 'customer'): EntityInterface<Customer>;
    get(config: 'calendar'): CalendarEntityInterface;
    get(config: 'consumer_booking'): ConsumerBookingEntityInterface;
    get(config: 'development_project'): DevelopmentProjectEntityInterface;
    get(config: 'device'): DeviceEntityInterface;
    get(config: 'discount_tag'): DiscountTagEntityInterface;
    get(config: 'document'): DocumentEntityInterface;
    get(config: 'corporate_namespace'): CorporateNamespaceEntityInterface;
    get(config: 'employee_contract'): EmployeeContractEntityInterface;
    get(config: 'employee_document'): EmployeeDocumentEntityInterface;
    get(config: 'employee'): EmployeeEntityInterface;
    get(config: 'entrance_card'): EntranceCardEntityInterface;
    get(config: 'entrance'): EntranceEntityInterface;
    get(config: 'eshop'): EshopEntityInterface;
    get(config: 'file'): FileEntityInterface;
    get(config: 'fiscal'): FiscalEntityInterface;
    get(config: 'inventorizationByDate'): InventorizationByDateEntityInterface;
    get(config: 'invoice'): InvoiceEntityInterface;
    get(config: 'listener_subscriber'): ListenerSubscriberEntityInterface;
    get(config: 'loose_relation'): LooseRelationEntityInterface;
    get(config: 'material'): MaterialEntityInterface;
    get(config: 'material_category'): MaterialCategoryEntityInterface;
    get(config: 'material_quant'): MaterialQuantEntityInterface;
    get(config: 'purchase'): PurchaseEntityInterface;
    get(config: 'maintenance'): MaintenanceEntityInterface;
    get(config: 'measurement_result'): MeasurementResultEntityInterface;
    get(config: 'ocr'): OcrEntityInterface;
    get(config: 'online_payments'): OnlinePaymentsEntityInterface;
    get(config: 'order'): OrderEntityInterface;
    get(config: 'payment_coupon'): PaymentCouponInterface;
    get(config: 'payment_provider'): PaymentProviderEntityInterface;
    get(config: 'order_consumer'): EntityInterface<OrderConsumer>;
    get(config: 'order_product'): OrderProductEntityInterface;
    get(config: 'order_proposal'): OrderProposalEntityInterface;
    get(config: 'order_proposal_product'): OrderProposalProductEntityInterface;
    get(config: 'payment'): PaymentEntityInterface;
    get(config: 'paypal'): PaypalEntityInterface;
    get(config: 'parameter'): ParameterEntityInterface;
    get(config: 'printer'): PrinterEntityInterface;
    get(config: 'pos_account'): PosAccountEntityInterface;
    get(config: 'product'): ProductEntityInterface;
    get(config: 'production'): ProductionEntityInterface;
    get(config: 'production_operation'): ProductionOperationEntityInterface;
    get(config: 'product_container'): ProductContainerEntityInterface;
    get(config: 'shipment'): ShipmentEntityInterface;
    get(config: 'shipment_order'): ShipmentOrderEntityInterface;
    get(config: 'shipment_product'): ShipmentProductEntityInterface;
    get(config: 'shop'): ShopEntityInterface;
    get(config: 'shop-cart'): ShopCartEntityInterface;
    get(config: 'stripe'): StripeEntityInterface;
    get(config: 'smart_tag_product'): SmartTagProductEntityInterface;
    get(config: 'supplier'): SupplierEntityInterface;
    get(config: 'tax'): TaxEntityInterface;
    get(config: 'ledger'): LedgerEntityInterface;
    get(config: 'template'): TemplateEntityInterface;
    get(config: 'user'): UserEntityInterface;
    get(config: 'user_menu'): UserMenuEntityInterface;
    get(config: 'v2-auth'): V2AuthEntityInterface;
    get(config: 'workplace'): WorkplaceEntityInterface;
    get(config: 'workshift'): WorkshiftEntityInterface;
    // master entities
    get(config: 'server'): ServerEntityInterface;
    get(config: 'serv'): ServerEntityInterface;
    get(config: 'container'): ContainerEntityInterface;
    get<T = any>(config: EntityNameType|EntityConfigInterface): EntityInterface<T>;

    get(config: EntityNameType|EntityConfigInterface) {
        const entityConfig = (typeof config === 'string') ?
            (resourcesConfig[config]?.params?.entityConfig) || { name: config } : config;

        return new EntityInstance(entityConfig, this.Request, this.store);
    }
}
