import { EntityInterface } from '@proman/services/entity.service';
import { Confirmation } from '@proman/interfaces/entity-interfaces';

export const confirmation = {
    name: 'confirmation',
    params: {
        entityConfig: {
            name: 'confirmation',
            get: ['types', 'drivers',],
        }
    }
};

export interface ConfirmationClassData {
    id?: string;
    name?: string;
    type: string;
    class: string;
    form: {
        [key: string]: {
            default: any;
            description: string;
            required: boolean;
            type: string;
        };
    };
}

export interface ConfirmationClassObject {
    [key: string]: ConfirmationClassData;
}

export interface ConfirmationEntityInterface extends EntityInterface<Confirmation> {
    types: () => Promise<string[]>;
    drivers: () => Promise<ConfirmationClassObject>;
}

