import { EntityInterface } from '@proman/services/entity.service';
import { ProductionOperation } from '../interfaces/entity-interfaces';

export const production_operation = {
    name: 'production_operation',
    params: {
        searchFields: [
            'articleOperation.operation.name',
            'production.name',
            'production.id'
        ],
        entityConfig: {
            name: 'production_operation',
            post: [
                'updatePlannedStart',
                'updateBookings',
                'updateDuration',
                'start',
                'end',
                'endMultiple',
                'startMultiple',
                'cancel',
                'confirm',
                'copyBooking',
                'removeBooking',
                'revertStatus'
            ],
            attrs: {
                UNSTARTED: 'unstarted',
                WAITING_FOR_PREVIOUS: 'waiting_for_previous',
                STARTED: 'started',
                FINISHED: 'finished',
                UNCONFIRMED: 'unconfirmed',
                CONFIRMED: 'confirmed',
                REPORTED: 'reported',
                REPORT_CONFIRMED: 'report_confirmed',
                CANCELED: 'canceled',
                BOOKED: 'booked'
            }
        },
        extraParameters() {
            return {
                select: ['id', 'plannedStart',  'plannedEnd',  'realStart', 'articleOperation', 'production', 'status'],
                sort: { plannedStart: 'desc' }
            };
        }
    }
};

export interface ProductionOperationEntityInterface extends EntityInterface<ProductionOperation> {
    UNSTARTED: 'unstarted';
    WAITING_FOR_PREVIOUS: 'waiting_for_previous';
    STARTED: 'started';
    FINISHED: 'finished';
    UNCONFIRMED: 'unconfirmed';
    CONFIRMED: 'confirmed';
    REPORTED: 'reported';
    REPORT_CONFIRMED: 'report_confirmed';
    CANCELED: 'canceled';
    BOOKED: 'booked';
    updatePlannedStart: (data: { id: number; plannedStart: string }) => Promise<any>;
    updateBookings: (data: any) => Promise<any>;
    updateDuration: (data: { id: number; duration: string }) => Promise<any>;
    start: (data: { id: number; bookingId: number }) => Promise<any>;
    end: (data: { id: number; bookingId: number }) => Promise<any>;
    endMultiple: (data: { ids: number[] }) => Promise<any>;
    startMultiple: (data: { ids: number[] }) => Promise<any>;
    cancel: (data: { id: number; bookingId: number }) => Promise<any>;
    copyBooking: (data: {  resourceId: number; bookingId: number; number?: number|string }) => Promise<any>;
    removeBooking: (data: { bookingId: number }) => Promise<any>;
    revertStatus: (data: { id: number; bookingId: number }) => Promise<any>;
}
