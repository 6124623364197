import { EntityInterface } from '@proman/services/entity.service';
import { Workshift } from '@proman/interfaces/entity-interfaces';

export const workshift = {
    name: 'workshift',
    params: {
        entityConfig: {
            name: 'workshift',
            autocompleteProperties: ['alias', 'name'],
            post: ['addNamespace', 'removeNamespace']
        },
    }
};

export interface WorkshiftEntityInterface extends EntityInterface<Workshift> {
    addNamespace: (data: { workshift: number, nameSpace: number }) => Promise<any[]>;
    removeNamespace: (data: { workshift: number, nameSpace: number }) => Promise<any[]>;
}
