import { EntityInterface } from '@proman/services/entity.service';

export const element = {
    name: 'element',
    params: {
        entityConfig: {
            name: 'element',
            get: [
                'list',
            ],
            post: [
                'render'
            ]
        }
    }
};

export interface ElementEntityInterface extends EntityInterface {
    list: () => Promise<any[]>;
}
