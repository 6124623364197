import { EntityInterface } from '../services/entity.service';
import { OrderProposal, ParameterDropdownOption, OrderProposalProduct} from '@proman/interfaces/entity-interfaces';

export const shopcart = {
    name: 'shop-cart',
    params: {
        entityConfig: {
            name: 'shop/cart',
            notApi: true,
            get: [
                'list',
                'getMaterialOptions',
                'get',
                'getProduct',
                'getArticle',
                'new',
            ],
            post: [
                'add',
                'remove',
                'reset',
                'currency',
                'language',
                'removeProduct',
                'updateProduct',
                'updateParameter',
                'addCouponCode',
                'deleteAccount',
                'updatePerson',
                'builderCreate',
            ],
        }
    }
};

export interface ShopCartEntityInterface extends EntityInterface {
    add: (data: { article: number; quantity: number; ids: number[]; values: any[]; proposal?: string; configuration?: any }) => Promise<any>;
    list: () => Promise<any>;
    remove: (data: { id: number }) => Promise<void>;
    get: (data: { id: string }) => Promise<OrderProposal>;
    getProduct: (data: { id: string }) => Promise<any>;
    deleteAccount: (data: { id: string|number }) => Promise<any>;
    getArticle: (data: { id: string }) => Promise<any>;
    removeProduct: (data: { id: number }) => Promise<OrderProposal>;
    addCouponCode: (data: { code: string; id: string }) => Promise<OrderProposal>;
    getMaterialOptions: (data: { parameterId: number; ids: number[]; values: any[]; type: string }) => Promise<ParameterDropdownOption[]>;
    updateProduct: (data: { id: number; quantity: number }) => Promise<OrderProposal>;
    updateParameter: (data: { id: number; value: any }) => Promise<any>;
    currency: (data: { currency: string }) => Promise<void>;
    language: (data: { language: string }) => Promise<void>;
    updatePerson: (data: any) => Promise<any>;
    reset: () => Promise<unknown>;
    new: () => Promise<unknown>;
    builderCreate: (data: any) => Promise<unknown>;
}
