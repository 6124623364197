export const notification = {
    name: 'notification',
    params: {
        entityConfig: {
            name: 'notification',
            get: ['getObjects', 'getFields', 'getEnumValues', 'getOperators'],
            post: ['removeAll']
        }
    }
};
