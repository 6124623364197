import { EntityInterface } from '@proman/services/entity.service';

export const report_sales = {
    name: 'report_sales',
    params: {
        entityConfig: {
            name: 'report_sales',
            get: [
                'loadProductDifference',
            ]
        }
    }
};

export interface ReportSalesEntityInterface extends EntityInterface {
    loadProductDifference: (data: { from: string; to: string; type: string }) => Promise<any>;
}
