export const feature = {
    name: 'feature',
    params: {
        entityConfig: {
            name: 'feature',
            post: [
                'raiseValue',
                'lowerValue'
            ],
            attrs: {
                DEFAULT_SORT: { 'options.position': 'asc' }
            }
        }
    }
};
