import { EntityInterface } from '@proman/services/entity.service';
import {
    BankTransaction,
    Employee,
    Salary,
    GeneralLedgerRecord,
    Tax, Ledger
} from '@proman/interfaces/entity-interfaces';
import {Money, text} from '@proman/interfaces/common.interface';

export const ledger = {
    name: 'ledger',
    params: {
        entityConfig: {
            name: 'ledger',
            get: [
                'getLedgerTypesList',
            ],
        },
        get: {
            join: [
                'ledgerType',
            ]

        },
    }
};

export interface LedgerEntityInterface extends EntityInterface<Ledger> {
    getLedgerTypesList: () => Promise<string[]>;

}
