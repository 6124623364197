import { EntityInterface } from '@proman/services/entity.service';
import { DevelopmentProject } from '@proman/interfaces/entity-interfaces';

export const development_project = {
    name: 'development_project',
    params: {
        searchFields: ['name', 'objective', 'comments'],
        entityConfig: {
            name: 'development_project',
            post: [
                'finish',
                'suspend',
                'start',
                'sendConclusion',
            ],
            attrs: {
                ACTIVE: 'active',
                WON: 'won',
                ARCHIVED: 'archived',
                TASK_WAITING: 'waiting',
                TASK_STARTED: 'started',
                TASK_FAILED: 'failed',
                TASK_SUCCEEDED: 'succeeded'
            }
        }
    }
};

export interface DevelopmentProjectEntityInterface extends EntityInterface<DevelopmentProject> {
    ACTIVE: 'active';
    WON: 'won';
    ARCHIVED: 'archived';
    TASK_WAITING: 'waiting';
    TASK_STARTED: 'started';
    TASK_FAILED: 'failed';
    TASK_SUCCEEDED: 'succeeded';

    sendConclusion: (data: { id: number }) => Promise<any>;
}
