import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

/**
 * `CursorLoading` service
 *
 */
@Injectable({ providedIn: 'root' })
export class CursorLoadingService {
    private Renderer2: Renderer2;

    constructor(rendererFactory: RendererFactory2) {
        this.Renderer2 = rendererFactory.createRenderer(null, null);
    }

    start() {
        this.Renderer2.addClass(document.body, 'CursorLoading');

    };

    stop() {
        this.Renderer2.removeClass(document.body, 'CursorLoading');
    }
}
