import { EntityInterface } from '@proman/services/entity.service';

export const stripe = {
    name: 'stripe',
    params: {
        entityConfig: {
            name: 'stripe',
            post: [
                'createStandardAccount',
                'createIndividualAccount',
            ]
        }
    }
};

export interface StripeEntityInterface extends EntityInterface {
    createStandardAccount: () => Promise<any>;
    createIndividualAccount: () => Promise<any>;
}
