import { EntityInterface } from '@proman/services/entity.service';

export const report_materials = {
    name: 'report_materials',
    params: {
        entityConfig: {
            name: 'report_materials',
            get: [
                'loadMaterialDifference',
                'loadMaterialCategoryDifference',
                'loadMaterialConsumption',
                'loadMaterialCategoryConsumption',
            ]
        }
    }
};

export interface ReportMaterialsEntityInterface extends EntityInterface {
    loadMaterialDifference: (data: { from: string; to: string; materials?: string }) => Promise<any>;
    loadMaterialCategoryDifference: (data: { from: string; to: string; materials?: string }) => Promise<any>;
    loadMaterialConsumption: (data: { from: string; to: string; materials?: string }) => Promise<any>;
    loadMaterialCategoryConsumption: (data: { from: string; to: string; materials?: string }) => Promise<any>;
}
