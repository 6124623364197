
export const permission = {
    name: 'permission',
    get: [
        'allAvailablePermissions',
        'employeesPermissions',
        'rolesPermissions',
        'specialisationsPermissions'
    ]
};
