import { EntityInterface } from '../services/entity.service';
import { ArticleOperation } from '../interfaces/entity-interfaces';

export const article_operation = {
    name: 'article_operation',
    params: {
        entityConfig: {
            name: 'article_operation',
            post: [
                'updateArticlesOperationsQualityControl',
            ],
        }
    }

};

export interface ArticleOperationEntityInterface extends EntityInterface<ArticleOperation> {
    updateArticlesOperationsQualityControl: (data: { id: number|number[]; [key: string]: any }) => Promise<any>;
}
