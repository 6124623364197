import { Injectable, Inject } from '@angular/core';
import { CONFIG } from '../config';
import { getIndexByProperty, getRandomString, isArray } from '@proman/utils';
import { PreferencesService } from './preferences.service';
import { AppLoader } from '@proman/services/app-loader.service';
import { MqttClient } from 'mqtt';
import { ToastService } from './toast.service';
import { Subject } from '@proman/rxjs-common';
import { CurrUser } from '../interfaces/object-interfaces';
import { UI_HIDE_WARNINGS } from '@proman/services/ui-preferences.service';
import { UiPreferencesService } from '@proman/services/ui-preferences.service';
import { FilterService } from './filter.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { getPublicSystemOptions, setUpdateStatus} from '@proman/store/system-options';
import { loadErrorsTranslations } from '@translations/json-language-loader';
import { LoggerService } from '@proman/services/logger.service';

const E_USER_ERROR = 'E_USER_ERROR';
const E_USER_WARNING = 'E_USER_WARNING';

const TYPE_ERROR = 'error';
const TYPE_WARNING = 'warning';
const TYPE_INFORMATION = 'information';
const TYPE_INFORMATION_SHORT = 'info';
const TYPE_SUCCESS = 'success';
const TYPE_UPDATE_START = 'update_start';
const TYPE_UPDATE_END = 'update_end';

const WS_PROTOCOL = 'wss';
const WS_PORT = '8084';
const WS_SUFFIX = '/ws';

interface WebsocketChannelSubscription {
    channel: string;
    callback: (value: string) => any;
    multi: boolean; // multi action subscriptions work as default handlers and custom
}

interface WebsocketMessageType {
    topic: string;
    data: any;
}

@Injectable({ providedIn: 'root' })
export class WebsocketService {
    client: MqttClient;
    currUser: CurrUser;
    reconnectCount: number = 0;
    domain: string = CONFIG.domain;
    namespace: string;
    message: Subject<WebsocketMessageType> = new Subject<WebsocketMessageType>();
    subscriptions: WebsocketChannelSubscription[] = [];
    defaultChannels: string[] = [];
    translations: { [key: string]: string} = {};

    constructor(
        @Inject(AppLoader) private AppLoader: AppLoader,
        private Preferences: PreferencesService,
        private Toast: ToastService,
        private store: Store,
        private UiPrefs: UiPreferencesService,
        private Logger: LoggerService,
        private Filter: FilterService,
    ) {
        this.store.select(getPublicSystemOptions).subscribe((value) => {
            this.namespace = value.namespace ?? CONFIG.namespace;
        });

        loadErrorsTranslations(`${this.Preferences.language() || 'en'}`)
            .then((res) => { this.translations = res; });

        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);

        (window as any).triggerUpdate = (data: boolean) => this.store.dispatch(setUpdateStatus({payload: data}));

    }

    startSession() {
        const getMqtt = () => (window as any).mqtt;
        let mqtt = getMqtt();

        if (this.client) return;

        const currUser = this.currUser;
        const namespace = this.namespace;
        const data = {
            clientId: `mqtt.proman.app/${getRandomString()}`,
            // username: currUser.services.ws.username,
            // password: currUser.services.ws.password,
            username: 'master',
            password: '81151DA133EF5E7D3C45B04EABDD3817DBEFBD3D0133B225F43296B75A2198FF',
            reconnectPeriod: 5 * 1000
        };

        if (!currUser || !currUser.person) return;

        this.client = mqtt?.connect('wss://mqtt.proman.app:8084/ws', data);
        // this.client = mqtt.connect(currUser.services.ws.url, data);

        const defaultChannel = `${namespace}/person/${currUser.person.id}/message`;
        const messageChannel = `${namespace}/message`;
        const systemChannel = `${namespace}/system`;
        const eventChannel = `${namespace}/event`;

        this.defaultChannels.push(defaultChannel, messageChannel, systemChannel, eventChannel);

        this.client?.on('connect', () => {
            this.Logger.log('WS connected')
            this.reconnectCount = 0;

            const defaultCallback = (msg: string) => this.Toast.pop(msg[0] as 'info' , msg[1], msg[2]);

            this.defaultChannels.forEach((channel) => this.subscribeChannel(channel, defaultCallback, true));
        });

        this.client?.on('error', (error: any) => {
            console.warn('mqtt ws error', error);
            // this.Toast.pop('error', 'websocket_connection_error');
        });

        this.client?.on('disconnect', () => this.Toast.pop('error', 'websocket_connection_lost'));

        this.client?.on('message', (topic: string, message: any) => {
            let data: any;

            console.log('subscriptions', this.subscriptions);

            this.Logger.log(`WS message received on topic '${topic}': ${message}`);

            if (this.getSubscriptions(topic, true).length) {
                this.Logger.log(`WS topic ${topic} matches subs:`, this.getSubscriptions(topic, true));
            }

            const isDisableTimeout = topic.includes('kitchen');

            try {
                data = JSON.parse(message.toString());
            } catch (e) {
                data = message.toString();
            }

            this.message.next({ topic, data });

            if (isArray(data)) {
                const values = data.length >= 3 ? data[2] : undefined;

                switch (data[0]) {
                    case TYPE_UPDATE_START:
                    case TYPE_UPDATE_END:
                        this.AppLoader.remove();
                        this.store.dispatch(setUpdateStatus({ payload: true }));
                        break;

                    case TYPE_ERROR:
                    case E_USER_ERROR:
                        this.handleMessage('error', data[1], values, isDisableTimeout);
                        break;

                    case TYPE_WARNING:
                    case E_USER_WARNING:
                        this.handleMessage('warning', data[1], values, isDisableTimeout);
                        break;

                    case TYPE_INFORMATION:
                    case TYPE_INFORMATION_SHORT:
                        this.handleMessage('info', data[1], values, isDisableTimeout);
                        break;

                    case TYPE_SUCCESS:
                        this.handleMessage('success', data[1], values, isDisableTimeout);
                        break;

                    default:
                        console.log('DEFAULT', data[0]);
                        this.getSubscriptions(topic).forEach((item) => {
                            item.callback(data);
                        });
                }

                this.getSubscriptions(topic, true).forEach((item) => {
                    item.callback(data);
                })

            }



        });

        this.client?.on('reconnect', (data: any) => {
            this.Logger.log('WS reconnect attempt...');
            if (++this.reconnectCount === 5) this.client.end(true);
        });

        this.client?.on('close', () => {
            this.Logger.log('WS connection closed');
        });

    }

    getSubscriptions(topic: string, multi: boolean = false): WebsocketChannelSubscription[] {
        return this.subscriptions.filter((sub) => sub.channel === topic && sub.multi === multi);
    }

    subscribeChannel = (channel: string, callback: (value: any) => any, silent?: boolean, multi: boolean = false) => {
        this.subscribeTopic(channel, silent);

        this.subscriptions.push({ channel, callback, multi });
    };

    unsubscribeChannel = (channel: string, callback?: any) => {
        this.unsubscribeTopic(channel);

        const index = getIndexByProperty(this.subscriptions, 'channel', channel);

        if (index) this.subscriptions.splice(index, 1);

        this.subscriptions = this.subscriptions.filter((sub) => {
            if (sub.channel !== channel) return true;
            if (sub.channel === channel) {
                if (!callback) return false;
                if (callback) return callback === sub.callback;
            }
        });
    };

    handleMessage = (type: 'info'|'error'|'warning'|'success', message: string, translateValues: any = {}, disableTimeOut: boolean = false) => {
        if (this.UiPrefs.get(UI_HIDE_WARNINGS) ?? false) return;
        this.Toast.pop(type, message, translateValues, { disableTimeOut: disableTimeOut });
    };

    /**
     *
     * @param topic
     * @param data recomended format: [ChannelName, ...otherData]
     */
    send = (topic: string, data: any) => {
        if (this.client) {
            this.Logger.log('WS send', topic, data);

            this.client.publish(topic, JSON.stringify(data), {retain: false, qos: 1, dup: false});
        } else {
            this.Logger.log('WS send failed,  no client');
        }
    };

    subscribeTopic(topic: any, silent?: boolean) {
        this.Logger.log('WS subscribeTopic', topic, 'silent', silent);
        this.client?.subscribe(topic, () => {
            if (!silent) console.log(`WS Channel '${topic}' subscribed`);
        });
    }

    unsubscribeTopic(topic: string) {
        if (!this.client) {
            return;
        }
        this.client.unsubscribe(topic, () => this.Logger.log(`WS Channel '${topic}' unsubscribed`));
    }

    endSession() {
        if (this.client) {
            this.Logger.log('WS end session');
            this.client.end(true);
        }

        this.client = null;

    }

    isConnected = () => {
        const result = !!this.client;
        console.log('WS is connected', result);

        return result;
    };

}
