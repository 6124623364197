export const statuses = {
    UNSTARTED: 'unstarted',
    WAITING_FOR_PREVIOUS: 'waiting_for_previous',
    STARTED: 'started',
    FINISHED: 'finished',
    UNCONFIRMED: 'unconfirmed',
    CONFIRMED: 'confirmed',
    REPORTED: 'reported',
    REPORT_CONFIRMED: 'report_confirmed',
    CANCELED: 'canceled',
    BOOKED: 'booked',
    SUSPENDED: 'suspended',
};
export const event = {
    name: 'event',
    params: {
        entityConfig: {
            name: 'event',
            post: [
                'updateComments',
                'updatePlannedStart',
                'updateBookings',
                'updateDuration',
                'start',
                'end',
                'startOutsourced',
                'endOutsourced',
                'cancel',
                'confirm'
            ],
            attrs: statuses,
            QueryBuilder: {
                aggregate: () => {
                    return {
                        join: [
                            'articleOperation',
                            'articleOperation.operation',
                            'articleOperation.operation.parameters',
                            'articleOperation.operation.visibleMaterialCategories',
                            'workplace',
                            'workplace.workgroup',
                            'supervisor',
                            'parameters',
                            'parameters.masters',
                            'segmentParameter',
                            'segmentParameter.articleProductionParameter',
                            'eventBookings',
                            'eventBookings.workplace',
                            'eventBookings.employeeBookings',
                            'eventBookings.employeeBookings.employee',
                            'eventBookings.employeeBookings.specialisation',
                            'subcontractor',
                            'production',
                            'eventComments',
                            'eventComments.author',
                        ],
                        sort: {
                            plannedStart: 'asc'
                        }
                    };
                }
            }
        },
        searchFields: ['articleOperation.operation.name']
    }
};
