import { EntityInterface } from '@proman/services/entity.service';

export const calendar_integrations_controller = {
    name: 'calendar_integrations_controller',
    params: {
        entityConfig: {
            name: 'calendar_integrations_controller',
            post: [
                'createGoogleCalendarEvent',
                'createZoomEvent',
                'createTencentEvent',
            ],
        }
    }
};

export interface CalendarIntergationsControllerInterface extends EntityInterface {
    createGoogleCalendarEvent: (data: {
        personId: number; start: string; name: string; duration: number; participantsId: number[]; comments: string;
    }) => Promise<any>;
    createZoomEvent: (data: {
        personId: number; start: string; name: string; duration: number; participantsId: number[]; comments: string;
    }) => Promise<any>;
    createTencentEvent: (data: {
        personId: number; start: string; name: string; duration: number; participantsId: number[]; comments: string;
    }) => Promise<any>;
}
