import { BodyPressurePoint } from '@proman/interfaces/entity-interfaces';
import { EntityInterface } from '@proman/services/entity.service';

export const body_pressure_point = {
    name: 'body_pressure_point',
    params: {
        entityConfig: {
            name: 'body_pressure_point',
            post: [
                'last',
                'getPoint',
            ],
            directions: [
                'older',
                'newer',
            ]
        }
    }
}

export interface BodyPressurePointEntityInterface extends EntityInterface<BodyPressurePoint> {
    last: (data: { customerEmployee: number }) => Promise<unknown>;
    getPoint: (data: { id: number; customerEmployee: number; direction: 'older'|'newer' }) => Promise<unknown>;
}

