import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppLoader {
    remove() {
        const appLoader = document.querySelector('.AppLoader');

        if (appLoader) appLoader.remove();
    }
}
