export const event_link = {
    name: 'event_link',
    params: {
        entityConfig: {
            name: 'event_link',
            QueryBuilder: {
                aggregate() {
                    return {
                        join: [
                            'previousEvent',
                            'previousEvent.articleOperation',
                            'previousEvent.articleOperation.operation'
                        ]
                    };
                }
            }
        }
    }
};
