import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrencyData } from './currency.actions';

export const currencyKey: string = 'currency';

export const selectCurrencyState = createFeatureSelector<CurrencyData>(currencyKey);

export const getCurrencies = createSelector(
    selectCurrencyState, (state: CurrencyData) => state.data
);
