import { EntityInterface } from '@proman/services/entity.service';
import { ProductContainer } from '@proman/interfaces/entity-interfaces';

export const product_container = {
    name: 'product_container',
    params: {
        entityConfig: {
            name: 'product_container',
            get: [
                'getBarcodeZpl',
                'downloadBarcodeZpl'
            ],
            post: [
                'createBatch',
                'moveToStore',
                'addToContainer',
                'removeFromContainer',
                'return',
                'updateQuantity',
                'moveToQuarantine',
            ]
        },
        searchFields: [
            'product.name',
            'production.id',
            'production.order.number',
            'production.order.name'
        ],
        attrs: {
            STATUS_UNCONFIRMED: 'unconfirmed',
            STATUS_STORED: 'stored',
            STATUS_RECEIVED: 'shipped',
            STATUS_READY_TO_SHIP: 'ready_to_ship',
            STATUS_QUARANTINE: 'quarantine',
        }
    }
};

export interface ProductContainerEntityInterface extends EntityInterface<ProductContainer> {
    addToContainer: (data: { id: number; shipmentContainer: number }) => Promise<unknown>;
    return: (data: { id: number; returned: number }) => Promise<unknown>;
    removeFromContainer: (data: { id: number }) => Promise<unknown>;
    moveToStore: (data: { id: number }) => Promise<unknown>;
    moveToQuarantine: (data: { id: number }) => Promise<unknown>;
    updateQuantity: (data: { id: number; quantity: number|string }) => Promise<unknown>;
}
