import { EntityInterface } from '@proman/services/entity.service';
import { Article, EmployeeDocument, PromanFile } from '@proman/interfaces/entity-interfaces';
import { SelectOption } from '@proman/interfaces/object-interfaces';

export const employee_document = {
    name: 'employee_document',
    params: {
        entityConfig: {
            name: 'employee_document',
            get: [
                'getByEmployee',
                'employeesDocumentsRead',
            ]
        }

    }
};
export interface EmployeeDocumentEntityInterface extends EntityInterface<EmployeeDocument> {
    employeesDocumentsRead: (data: { id: number[] }) => Promise<Array<{ id: number; read: boolean; date: string }>>;
    getByEmployee: (id: number) => Promise<{ documents: EmployeeDocument[]; reads: { id: number; date: string; signature: PromanFile, documentId: number }[] }>;
}

export const getFamilyConditionsList = (translateFn: ((key: string) => string)): SelectOption[] => {
    const list = [
        { id: 'notMarried', name: 'not_married' },
        { id: 'marriedWifeNotWorkingHasNoKids', name: 'married_wife_not_working_has_no_kids' },
        { id: 'marriedWifeNotWorkingHas1Child', name: 'married_wife_not_working_has_1_child' },
        { id: 'marriedWifeNotWorkingHas2Children', name: 'married_wife_not_working_has_2_children' },
        { id: 'marriedWifeNotWorkingHas3Children', name: 'married_wife_not_working_has_3_children' },
        { id: 'marriedWifeNotWorkingHas4Children', name: 'married_wife_not_working_has_4_children' },
        { id: 'marriedWifeNotWorkingHas5Children', name: 'married_wife_not_working_has_5_children' },
        { id: 'marriedWifeWorkingHasNoKids', name: 'married_wife_working_has_no_kids' },
        { id: 'marriedWifeWorkingHas1Child', name: 'married_wife_working_has_1_child' },
        { id: 'marriedWifeWorkingHas2Children', name: 'married_wife_working_has_2_children' },
        { id: 'marriedWifeWorkingHas3Children', name: 'married_wife_working_has_3_children' },
        { id: 'marriedWifeWorkingHas4Children', name: 'married_wife_working_has_4_children' },
        { id: 'marriedWifeWorkingHas5Children', name: 'married_wife_working_has_5_children' },
    ];

    return list.map((item) => ({ ...item, name: translateFn(item.name) }));
};

export interface employeesDocumentsEntityInterface extends EntityInterface<EmployeeDocument> {
    updateMultiple: (data: any) => Promise<any>;
    importEmployeesDocumentsRead: (data: any) => Promise<any>;
}
