import { EntityInterface } from '../services/entity.service';

export const dynamic_table = {
    name: 'dynamic_table',
    params: {
        entityConfig: {
            name: 'dynamic_table',
            get: [
                'getEntities'
            ],
            post: [
                'copy',
                'addFileAssociation',
                'removeFileAssociation'
            ]
        },
        dateColumnDefaultTypes: ['yesterday', 'today', 'tomorrow', 'specific'],
        COLUMN_TYPES: [
            'integer',
            'string',
            'text',
            'entity',
            'date',
            'price',
            'duration',
            'dropdown',
            'boolean',
            'entity_field',
            'float',
        ],
        COLUMN_TYPES_WITH_EXPRESSION: [
            'integer',
            'string',
            'price',
            'float',
        ]
    }
};

export interface DynamicTableEntityInterface extends EntityInterface {
    getEntities: () => Promise<string[]>;
    copy: (data: { id: number; entityName: string }) => Promise<any>;
    addFileAssociation: (data: { id: number; fileId: number; entityName: string }) => Promise<any>;
    removeFileAssociation: (data: { id: number; fileId: number; entityName: string }) => Promise<any>;
}
