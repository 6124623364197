import { EntityInterface } from '@proman/services/entity.service';
import { CorporateNamespace } from "@proman/interfaces/entity-interfaces";

export const corporate_namespace = {
    name: 'corporate_namespace',
    params: {
        searchFields: ['name', 'objective', 'comments'],
        entityConfig: {
            name: 'corporate_namespace',
            post: [
                'create',
                'addChild',
                'removeChild'
            ],
        }
    },
    get: {
        join: [
            'children'
        ]
    }
};

export interface CorporateNamespaceEntityInterface extends EntityInterface<CorporateNamespace> {
    addChild: (data: { parent: string; child: string; }) => Promise<any[]>;
    removeChild: (data: { parent: string; child: string; }) => Promise<any[]>;
  }
