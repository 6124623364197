import { createAction, props } from '@ngrx/store';

import {CorporateNamespace, PublicSystemOptions, SystemOptions} from '../../interfaces/entity-interfaces';

export enum SystemOptionsActions {
    SetData = `[SystemOptions] Set Data`,
    UpdateData = `[SystemOptions] Update Data`,
    LoadData = `[SystemOptions] Load Data`,
    SetPublicData = `[SystemOptions] Set Public Data`,
    LoadPublicData = `[SystemOptions] Load Public Data`,
    SetUpdateStatus = `[SystemOptions] Set Update Status`,
    CloseDialog = `[SystemOptions] Close Dialog`,
    SetNamespaces = `[SystemOptions] Set Namespaces`,
    LoadNamespaces = `[SystemOptions] Load Namespaces`,
}

export const setSystemOptions = createAction(
    SystemOptionsActions.SetData,
    props<{ payload: SystemOptions }>()
);

export const updateSystemOptions = createAction(
    SystemOptionsActions.UpdateData,
    props<{ payload: { key: string; value: number|string } }>()
);

export const loadSystemOptions = createAction(
    SystemOptionsActions.LoadData
);

export const setPublicSystemOptions = createAction(
    SystemOptionsActions.SetPublicData,
    props<{ payload: PublicSystemOptions }>()
);

export const loadPublicSystemOptions = createAction(
    SystemOptionsActions.LoadPublicData
);

export const setUpdateStatus = createAction(
    SystemOptionsActions.SetUpdateStatus,
    props<{ payload: boolean }>()
);

export const closeDialog = createAction(
    SystemOptionsActions.CloseDialog
);

export const setNamespaces = createAction(
  SystemOptionsActions.SetNamespaces,
  props<{ payload: CorporateNamespace[] }>()
)

export const loadNamespaces = createAction(
  SystemOptionsActions.LoadNamespaces
)
