import { mapOption } from '@proman/utils';

const PROTOCOLS = [
    'http',
    'rtsp'
];

export const camera = {
    name: 'camera',
    params: {
        entityConfig: {
            name: 'camera'
        },
        protocols: PROTOCOLS,
        getProtocols: () => (PROTOCOLS.map(mapOption))
    }

};
