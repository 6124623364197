import { EntityInterface } from '../services/entity.service';

export const shipment_product = {
    name: 'shipment_product',
    params: {
        entityConfig: {
            name: 'shipment_product',
            post: [
                'moveToShipment',
                'returnFromShipment'
            ]
        }
    }
};

export interface ShipmentProductEntityInterface extends EntityInterface {
    moveToShipment: (data: { 
        id: number; 
        quantity: number|string; 
        packagingQuantity: string|number; 
        packagedQuantity: number|string; 
        packagedPackagingQuantity: string|number;
    }) => Promise<any>;
    returnFromShipment: (data: { id: number; quantity: number|string; packagingQuantity: string|number }) => Promise<any>;
}
