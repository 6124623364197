export const shipment_container = {
    name: 'shipment_container',
    params: {
        entityConfig: {
            name: 'shipment_container',
            get: [ 'getBarcodeZpl' ],
            post: [ 'add' ]
        }
    }
};
