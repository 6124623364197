import { EntityInterface } from '@proman/services/entity.service';

export const payment = {
  name: 'payment',
  params: {
    entityConfig: {
      name: 'payment',
      post: [
        'getPaymentRatio',
      ]
    }
  }
};

export interface PaymentEntityInterface extends EntityInterface {
  getPaymentRatio: (data: { id: number|string }) => Promise<any>;
}
