import { EntityInterface } from '@proman/services/entity.service';

export const assets = {
    name: 'assets',
    params: {
        entityConfig: {
            name: 'assets',
            post: [
                'monthlyAmortization',
                'merge',
            ],
            get: [
                'pdf',
            ],
            uris: {
            },
        },
        searchFields: [
            'name',
            'alias',
            'workplace',
            'comments'
        ],
        get: {
            join: [
                'assetType', 'recordDbit', 'recordCrdt', 'recordDbit.accountCategory', 'recordCrdt.accountCategory', 'files'
            ],
            partialJoin: {
                createdBy: ['id', 'name'],
                updatedBy: ['id', 'name'],
                workplace: ['id', 'name'],
                purchase: ['id', 'supplier'],
                'purchase.supplier': ['id', 'name'],
            },
        },
    }
};

export interface AssetsEntityinterface extends EntityInterface {
    monthlyAmortization: (data: { id: number; month: string; year: string }) => Promise<any>;
    merge: (data: { id: number[] }) => Promise<unknown>;
}
