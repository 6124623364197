import { OrderProposalProduct } from '../interfaces/entity-interfaces';
import { EntityInterface } from '../services/entity.service';

export const order_proposal_product = {
    name: 'order_proposal_product',
    params: {
        entityConfig: {
            name: 'order_proposal_product',
            post: ['select', 'copy'],
        }
    }
};

export interface OrderProposalProductEntityInterface extends EntityInterface<OrderProposalProduct> {
    select: (data: { orderProposalProduct: number; product: number|string; orderProposal: number }) => Promise<unknown>;
}
