import { EntityInterface } from '@proman/services/entity.service';

export const payment_coupon = {
    name: 'payment_coupon',
    params: {
        entityConfig: {
            name: 'payment_coupon',
            get: [
                'getNumber'
            ]
        }
    }
};

export interface PaymentCouponInterface extends EntityInterface {
    getNumber: () => Promise<any>;
}
