import { EntityInterface } from '@proman/services/entity.service';
import { LoginProject } from '@proman/interfaces/object-interfaces';

export const user = {
    name: 'user',
    params: {
        entityConfig: {
            name: 'user',
            get: ['checkName', 'ping', 'errors'],
            post: [
                'updateSessionLanguage',
                'changePassword',
                'updateLanguage',
                'checkCardOwner'
            ]
        }
    }
};

export interface UserEntityInterface extends EntityInterface {
    changePassword: (data: { id: number; password: string }) => Promise<any>;
    checkCardOwner: (data: { _username: string; _token?: string; project?: LoginProject; _cardId?: string }) => Promise<unknown>;
}
