import { EntityInterface } from '@proman/services/entity.service';
import { AccountCategory, GeneralLedgerRecord } from '@proman/interfaces/entity-interfaces';
import { date } from '@proman/interfaces/common.interface';

export const account_category = {
    name: 'account_category',
    params: {
        get: {
            join: [
                'parent',
                'parent.parent',
                'parent.parent.parent',
                'parent.parent.parent.parent',
                'parent.parent.parent.parent.parent',
                'parent.parent.parent.parent.parent.parent',
                'parent.parent.parent.parent.parent.parent.parent'
            ]
        },
        entityConfig: {
            name: 'account_category',
            get: ['tree', 'getBalanceKeys', 'getBalanceTypes', 'getPurchaseTypes', 'getCategoriesList', 'getAccountCategory', 'getBalanceRemnant', 'getChildCategories'],
            post: ['removeTreeElement', 'removeTreeElements', 'getBalance']
        }
    }
};

export interface AccountCategoryEntityInterface extends EntityInterface<AccountCategory> {
    getBalanceKeys: () => Promise<any>;
    getBalanceTypes: () => Promise<string[]>;
    getPurchaseTypes: () => Promise<string[]>;
    getCategoriesList: () => Promise<string[]>;
    getAccountCategory: (data: { keyName: string }) => Promise<AccountCategory>;
    getChildCategories: (data: { id: number }) => Promise<number[]>;
    getBalanceRemnant: (data: { id: number|number[]; date: date }) => Promise<number>;
    getBalance: (data: { from: date; to: date }) => Promise<GeneralLedgerRecord[]>;
    tree: () => Promise<AccountCategory[]>;
}
