import { EntityInterface } from '../services/entity.service';
import { OrderConsumerBooking } from '../interfaces/entity-interfaces';

export const consumer_booking = {
    name: 'consumer_booking',
    params: {
        entityConfig: {
            name: 'consumer_booking',
            attrs: {
                CREATED: 'created',
                RESERVED: 'reserved',
                CANCELED: 'canceled',
                CONFIRMED: 'confirmed',
                COMPLETE: 'complete'
            },
        }
    }
};

export interface ConsumerBookingEntityInterface extends EntityInterface<OrderConsumerBooking> {
    CREATED: 'created';
    RESERVED: 'reserved';
    CANCELED: 'canceled';
    CONFIRMED: 'confirmed';
    COMPLETE: 'complete';
}
