import { InventorizationByDate } from '@proman/interfaces/entity-interfaces';
import { EntityInterface } from '@proman/services/entity.service';

export const inventorization_by_date = {
  name: 'inventorization_by_date',
  params: {
    entityConfig: {
      name: 'inventorization_by_date',
      post: [
        'getMaterialsList',
      ],
    }
  }
};

export interface InventorizationByDateEntityInterface extends EntityInterface<InventorizationByDate> {
  getMaterialsList: (data: { id: number, warehouse: number, inventorizationDate: string }) => Promise<{ materialId: number, quantId: number, name: string, unit: string, quantity: number }[]>;
}
