import { EntityInterface } from '../services/entity.service';

export const translation = {
  name: 'translation',
  params: {
      entityConfig: {
          name: 'translation',
          post: ['importKeys', 'addKey', 'updateGit']
      }
  }
};

export interface TranslationEntityInterface extends EntityInterface {
    addKey: (data: { key: string }) => Promise<any>;
    updateGit: (data: { code: string }) => Promise<any>;
    importKeys: (data: { file: number, auto_translate?: boolean }) => Promise<any>;
}
