import { EntityInterface } from "@proman/services/entity.service";

export const measurement_result = {
  name: 'measurement_result',
  params: {
    entityConfig: {
      name: 'measurement_result',
      get: [
        'latest',
      ]
    }
  }
};

export interface MeasurementResultEntityInterface extends EntityInterface {
  latest: (data: { customerEmployee: number }) => Promise<any[]>;
}
