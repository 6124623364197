import { PermissionType } from '../services/acl.service';
import { Currency, Person, PromanFile, SystemOptions } from './entity-interfaces';
import { EntityNameType } from '@proman/services/entity.service';

import { BtnThemePalette } from './btn-theme-pallete';

export { BtnThemePalette };

export type TableFieldFormatterType = undefined|'compile'|'money'|'numeric'|'duration'|'dateTime'|'input'|'parameters'|'directive'|'image'|'outputFilter'|'iconBoolean';

export type TableButtonAction = 'create'|'edit'|'remove'|'update_status'|'master'|'display'|'view'|'pay';

export interface TableButton {
    icon?: string;
    name?: string;
    callback?: (a?: any, b?: any) => any;
    pattern?: string;
    acl?: PermissionType|PermissionType[];
    action?: TableButtonAction;
    template?: string;
    tooltip?: string;
    faClass?: string;
    theme?: BtnThemePalette;
    label?: string;
    title?: string;
    state?: {
        name: string;
        key?: string;
        queryParams?: (row: unknown) => unknown;
    };
    defaultRowAction?: boolean;
    defaultRowShiftAction?: boolean;
    show?: (data?: any) => boolean;
    dialog2?: {
        component: any;
        locals?: {
            entity?: EntityNameType;
            mainField?: Partial<EntityItemFieldConfig>;
            parameters?: EntityItemFieldConfig[];
            header?: string;
            [key: string]: any;
        };
        options?: {
            width?: string;
            disableAutoFocus?: boolean;
            refreshOnClose?: boolean;
        };
    };
    onSuccess?: (item?: any) => any;
    onCancel?: () => any;
}

export interface TableFieldFilter {
    showSearch?: boolean;
    disabled?: boolean;
    type?: 'dropdown'|'dropdown_multi'|'autocomplete'|'search'|'date'|'numeric';
    keys?: string[];
    key?: string;
    value?: any;
    options?: unknown[];
    entity?: EntityNameType;
    userValueExpiry?: number;
    notStrict?: boolean;
    min?: unknown;
    max?: unknown;
}

export interface TableField {
    key: string;
    name?: string;
    getValue?: (row: any) => any;
    formatter?: TableFieldFormatterType;
    formatterConfig?: any;
    filter?: TableFieldFilter;
    sort?: false | any;
    sortable?: boolean;
    translate?: boolean;
    stats?: string[];
    prefix?: string;
    postfix?: string;
    maxLength?: number;
    state?: {
        name: string;
        key: string;
        id?: string;
        acl?: PermissionType;
    };
    _enabled?: boolean; // for table settings columns management
    hidden?: boolean; // to init field but column is hidden by default
    entityName?: string; // for tags formatter
    acl?: PermissionType;
    callback?: (row: any, value: any) => void;
    expression?: string; // dynamic query expression field
    entity?: EntityNameType; // dynamic query field data for editable fields
    config?: { statsConfig?: any; showFullResult?: any };
    showTime?: boolean; // for date filtering with time
    roles?: string[]; // for user type visibility filtering
    emitFilterValue?: (column: TableField, value: any) => void;
    _config?: any;
    avgFormat?: (value: any) => any;
    filterValue?: string;
    extraJoins?: string[];
    extraPartialJoins?: { [key: string]: string[] };
    preloadParametersData?: boolean;
    tooltip?: string;
    isDisabled?: (data?: any) => boolean;
    customFooterUnit?: string;
    cellMarker?: (row: any) => 'red'|'green'|'grey'|'purple'|'blue'|'yellow' |'red-bg'|'green-bg'|'grey-bg'|'purple-bg'|'blue-bg'|'yellow-bg'|null;
    treePadding?: boolean;
    additionalConfig?: any;
}

export interface TableConfig {
    entity?: EntityNameType;
    aclRoot?: string;
    tableId?: string;
    header?: string;
    headerState?: {
        name: string;
        key?: string;
        id?: string;
        acl?: PermissionType;
    };
    addButton?: TableButton|null;
    rowButtons?: TableButton[];
    actionButtons?: TableButton[];
    topButtons?: TableButton[];
    extraParameters?: {
        join?: string[];
        [key: string]: any;
    };
    fields?: TableField[];
    customFilters?: TableFilter[];
    quickFilters?: TableFilter[];
    multiselect?: boolean;
    transform?: (data: any[]) => any[];
    transformAfter?: (data: any[]) => Promise<any[]>;
    chartTransform?: (data: any[]) => any[];
    onRowClick?: (row: any, event?: MouseEvent) => any;
    onRowShiftClick?: (row: any, event?: MouseEvent) => any;
    rowMarker?: (row: any) => 'red'|'green'|'grey'|'purple'|'blue'|'yellow';
    dataSourceFiltering?: boolean;
    preloadParametersData?: boolean;
    hideSettings?: boolean;
    advancedSearch?: boolean;
    preventFixedHeader?: boolean;
    _modified?: boolean; // material table for material types display;
    onExport?: (column: TableField) => any;
    isChart?: boolean;
    customFooter?: { [column: string]: (data: any[]) => string };
    chartJoins?: string[];
    hideIfEmpty?: boolean;
    isDefaultFilterDisabled?: boolean;
    beforeLoad?: (data: { params: any }) => Promise<unknown>;
    useListNotSearch?: boolean;
    dynamicFields?: any;
    excludeDynamic?: boolean;
    enableReposition?: boolean;
}

export interface TableFilter {
    name: string;
    key?: string;
    value: unknown;
    isSelected?: boolean;
    isDefault?: boolean;
}

export interface TablePayload {
    data: any[];
    length: number;
    total: number;
}

export interface Action {
    icon: string;
    theme?: BtnThemePalette;
    callback: (data?: any) => any;
    disabled?: boolean;
    tooltip?: string;
    disabledTooltip?: string;
    isVisible?: (data?: any) => boolean;
    isDisabled?: (data?: any) => boolean;
    pending?: boolean;
    position?: number; // used for customizing actions
    hidden?: boolean // used for customizing actions
}

export interface SelectOption {
    id: number|string;
    name: string;
}

export type CurrUserType = 'employee'|'agent'|'customer';

export interface CurrUser {
    token: string;
    defaultCurrency: Currency;
    isEmployee: boolean;
    isCustomer: boolean;
    isAgent: boolean;
    deletable: boolean;
    id: number;
    language: string;
    languages: string[];
    master: boolean;
    mustChangePassword: boolean;
    twoFactorLoginEnabled: boolean;
    permissions: { [key in PermissionType]: boolean };
    person: Person;
    showHelp: boolean;
    timeZone: string|null;
    type: CurrUserType;
    username: string;
    customer?: any;
    photo?: PromanFile;
    ttl: number;
    services: {
        ws: {
            url: string|null;
            username: string|null;
            client: string|null;
            password: string|null;
            base: string|null;
        };
    };
    allowFrontend: boolean;
    allowPos: boolean;
    allowShop: boolean;
    bookkeepingUser: boolean;
    systemOptions?: SystemOptions;
}

export interface EntityItemFieldConfig {
    name: string;
    key: string;
    type: ParameterComponentType;
    config: {
        entity?: EntityNameType|object;
        disabled?: boolean;
        [key: string]: any;
    };
    notUnique?: boolean;
    value?: any;
    disabled?: boolean;
}

export type ParameterComponentType =
    'string'|
    'text'|
    'number'|
    'integer'|
    'autocomplete'|
    'dropdown'|
    'material_category'|
    'date'|
    'datetime'|
    'password'|
    'color'|
    'select'|
    'month'|
    'material'|
    'subcontractors'|
    'list'|
    'boolean'|
    'editable_expression'|
    'time'|
    'time_dropdown'|
    'entity'|
    'duration'|
    'files'|
    'price'|
    'parameter_group'|
    'workgroup'|
    'checkbox'|
    'tags'|
    'icon'|
    'account_category_type'|
    'label'|
    'emoji'|
    'html'|
    'accounting'|
    'weight'|
    'namespaces'|
    'list-manager';

export type UserType = 'employee'|'agent'|'customer';

export interface TabState {
    name: string;
    params: string[];
    acl?: PermissionType;
    chef?: boolean;

    corporateRestrict?: boolean;
    types?: UserType[];
    path?: string;
}

export type PromanRequest<T> = { [P in keyof Partial<T>]: any };

export type PromanUpdateRequest<T> = PromanRequest<T> & { id: number };

export type ExtendedType<T, K> = PromanRequest<T & K>;

export interface EditiableParameterInterface extends EntityItemFieldConfig {
    disabled?: boolean;
    key: string;
    value: any;
}

export interface PaginatedData<T> {
    data: T[];
    total: number;
    length: number;
}

export interface ProductionPermissions {
    start: { possible: boolean; messages: string[] };
    suspend: { possible: boolean; messages: string[] };
}

export interface AutocompleteConfig {
    label?: string;
    entity?: EntityNameType;
    entityParams?: any;
    displayKey?: string;
    cache?: boolean;
    isNone?: boolean;
    required?: boolean;
    getOptionName?: (item: any) => string;
    transform?: (item: any) => any;
    searchFields?: string[];
    emptyCollections?: string[];
    query?: string;
    preventClear?: boolean;
    autofocus?: boolean;
    entityGet?: string;
    important?: boolean;
    getOptions?: any;
    imageKey?: any;
    imageSize?: any;
    descKey?: string;
    hasColors?: boolean;
    floatLabel?: 'always' | 'auto';
    autoSelectSingleOption?: boolean;
    debounce?: number;
}

export interface LastUser {
    name: string;
    username: string;
}

export interface PaginationConfig {
    currentPage: number;
    limit: number;
    total: number;
}

export interface CountResponse {
    count: number;
}

export enum LoginProject {
    POS=  'pos',
    FRONTEND = 'frontend',
    SHOP = 'shop',
    HEALTH = 'health',
    MASTER = 'master',
    SMARTTAG = 'smarttag',
    SYSTEM = 'system',
    GOOGLE = 'google',
    MICROSOFT = 'microsoft'
}

export type LoginRequestType = { _username: string; _password: string; project: LoginProject } |
    { _token: string; project: LoginProject } |
    { cardId: string; project: LoginProject };

export interface EchartOrdinalData {
    xValue: string|number;
    values: string[]|number[];
}

export interface EchartOrdinalConfig {
    colors?: string[];
    labels: any[];
    hideSumData?: boolean;
    yAxis?: any;
    xAxis?: any;
}

export type PromanMenuType = PromanMenuItem[]|PromanParentMenuItem[];

export interface PromanMenuItem  {
    name: string;
    state: string;
    icon: string;
    acl?: string;

}

export interface PromanParentMenuItem {
    type: 'group';
    name: string;
    icon: string;
    state: string;
    acl?: string;
    tabs?: PromanMenuItem[];
}

export interface CancelableSequentialRequest {
  do: () => Promise<void>;
  cancel: () => void;
}

export interface ConfigItem {
    group: string;
    name: string;
    value: unknown;
    form: { [key: string]: unknown };
}

export interface ConfigStyleData {
    primaryColor: string;
    secondaryColor: string;
    menuStyle: string;
}

export interface EntityLogObject {
    current: { [key: string]: any },
    history: { [key: string]: any }[],
    count: number
}

