export const system_options = {
    name: 'system_options',
    params: {
        entityConfig: {
            name: 'system_options',
            get: [
                'timezones',
                'version',
            ],
            post: [
                'updateProman',
            ]
        }
    }
};
