import { EntityInterface } from '@proman/services/entity.service';
import { Workplace } from '@proman/interfaces/entity-interfaces';

export const workplace = {
    name: 'workplace',
    params: {
        entityConfig: {
            name: 'workplace',
            autocompleteProperties: ['alias', 'name'],
            post: ['copy']
        },
        searchFields: ['alias', 'name', 'workgroup.name'],
    }
};

export type WorkplaceEntityInterface = EntityInterface<Workplace>;
