import { EntityInterface } from '@proman/services/entity.service';

export const online_payments = {
    name: 'online_payments',
    params: {
        entityConfig: {
            name: 'public/online_payments',
            post: [
                'createStripeSession',
                'createPaypalOrder',
            ]
        }
    }
};

export interface OnlinePaymentsEntityInterface extends EntityInterface {
    createStripeSession: (data: { proposalId: string}) => Promise<any>;
    createPaypalOrder: (data: { proposalId: string}) => Promise<any>;
}
