import { EntityInterface } from '@proman/services/entity.service';

export const amortization = {
    name: 'amortization',
    params: {
        entityConfig: {
            name: 'amortization',
        },
        searchFields: [
            'month',
            'year',
            'asset.name',
            'comments'
        ],
        get: {
            join: [
                'asset',
            ],
            partialJoin: {
                createdBy: ['name'],
                updatedBy: ['name'],
            },
        },
    }
};

export interface AmortizationEntityinterface extends EntityInterface {
    monthlyAmortization: (data: { id: number; month: string; year: string }) => Promise<any>;
}
