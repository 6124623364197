// @ts-ignore
import { devProtocol, devUrl } from './config.dev';

const protocol = devProtocol;
const url = devUrl;

const rootUrl = `${protocol}//${url}/`;

function getApiUrl() {
    return rootUrl;
}

function getNamespace() {
    let string = getApiUrl().split(':')[1];
    let namespace = string.slice(2, string.length - 1);
    namespace = namespace.split('.')[0];
    // @ts-ignore
    if (url !== 'localhost') {
        return namespace;
    } else {
        return 'localhost';
    }
}

const NAMESPACE = getNamespace();
const ROOT = getApiUrl();

export const CONFIG: {
    domain: string;
    namespace: string;
    root: string;
    api: string;
    upload: string;
    defaultLanguage: string;
    languages: string[];
    helpUrl: string;
    version: string;
    uploadUrl: string;
} = {
    domain: url,
    namespace: NAMESPACE,
    root: ROOT,
    api: `${ROOT}api/`,
    upload: `${ROOT}upload`,
    defaultLanguage: 'en',
    languages: ['lt', 'en', 'fr', 'ru', 'pl', 'bg', 'tr', 'de', 'cn', 'es', 'ua', 'vn'],
    version: '2020.3',
    helpUrl: 'https://doc.proman.app/',
    uploadUrl: `${ROOT}api/file/upload`
};
