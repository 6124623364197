import { EntityInterface } from '../services/entity.service';
import { Supplier } from '../interfaces/entity-interfaces';

export const supplier = {
    name: 'supplier',
    params: {
        searchFields: [
           'id',
            'name'
        ],
        entityConfig: {
            name: 'supplier',
            get: [
                'getSupplierTypes',
            ],
            uris: {
                importSuppliers: 'import_suppliers',
            },
            post: [
              'importData',
            ]
        }
    }
};

export interface SupplierEntityInterface extends EntityInterface<Supplier> {
    getSupplierTypes: () => Promise<string[]>;
    importData: (data: any) => Promise<unknown>;
}
