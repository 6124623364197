import { EntityInterface } from '@proman/services/entity.service';

export const order_proposal = {
    name: 'order_proposal',
    params: {
        entityConfig: {
            name: 'order_proposal',
            attrs: {
                SUCCESSFUL: 'successful',
                WAITING: 'waiting',
                CANCELED: 'canceled',
                CONFIRMED: 'confirmed'
            },
            post: ['clean', 'createProposalFromProducts', 'getUserProposal'],
            uris: {
              importUrl: 'import'
            },
        },
        extraParameters() {}
    }
};

export interface OrderProposalEntityInterface extends EntityInterface {
    clean: (data: { id: number }) => Promise<any>;
    SUCCESSFUL: 'successful';
    WAITING: 'waiting';
    CANCELED: 'canceled';
    CONFIRMED: 'confirmed';
    importUrl: 'import';
    createProposalFromProducts: (data: { id: number[] }) => Promise<any>;
    getUserProposal: (data: { userId: number }) => Promise<any>;
}
