import { EntityInterface } from '@proman/services/entity.service';
import { Eshop } from '@proman/interfaces/entity-interfaces';

export const eshop = {
    name: 'eshop',
    params: {
        entityConfig: {
            name: 'eshop',
            post: [
              'test',
              'incCounter',
            ],
        },
    }
};

export interface EshopEntityInterface extends EntityInterface<Eshop> {
    test: (data: { id: number }) => Promise<{
        errorParams: any[];
        message: string;
        status: number;
    }>;
    incCounter: (data: any) => Promise<any>,
}
