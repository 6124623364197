export const sale_opportunity = {
    name: 'sale_opportunity',
    params: {
        get: {
            join: [
                'customer',
                'files',
                'contacts',
                'contacts.photo',
                'tags',
                'stage',
                'priority',
                'type'
            ],
            partialJoin: {
                'createdBy': ['name'],
                'updatedBy': ['name'],
                'files.createdBy': ['name'],
                'manager': ['name']
            },
        },
        searchFields: ['name', 'id', 'comments'],
        entityConfig: {
            name: 'sale_opportunity',
            post: ['reposition']
        }
    }
};
