import { EntityInterface } from '../services/entity.service';
import { Server } from '../interfaces/entity-interfaces';

export const server = {
  name: 'serv',
  params: {
      entityConfig: {
          name: 'serv',
          post: ['updateAll'],
      }
  }
};

export interface ServerEntityInterface extends EntityInterface<Server> {
    updateAll: (data: { id: number }) => Promise<unknown>;
}
