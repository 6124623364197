import { EntityInterface } from '../services/entity.service';
import { PromanFile } from '@proman/interfaces/entity-interfaces';
import { dateTime } from '@proman/interfaces/common.interface';

export const document = {
    name: 'document',
    params: {
        entityConfig: {
            name: 'document',
            post: [
                'createBillOfLading',
                'createBillOfContainers',
                'createBillOfContainersFile',
                'createBillsOfContainers',
                'createCustomerFile',
                'createEmployeeContract',
                'createPurchase',
                'regenerateBillOfLading',
                'customerInvoicesPrint',
                'additionalDocumentPrint',
                'createSupplierFile',
                'documentLogs',
            ]
        },
        types: [
            'document',
            'invoice',
            'bill_of_lading',
            'contract',
            'order_confirmation',
            'proforma'
        ]
    }
};

export interface DocumentEntityInterface extends EntityInterface {
    createPurchase: (data: { template: number; name: string; purchase: number }) => Promise<any>;
    createEmployeeContract: (data: { template: number; employee: number; id?: number }) => Promise<any>;
    customerInvoicesPrint: (data: { template: number; ids: number[] }) => Promise<any>;
    additionalDocumentPrint: (data: { template: number; ids?: number[] }) => Promise<any>;
    regenerateBillOfLading: (data: { template: number; order?: number; employee?: number }) => Promise<PromanFile>;
    createBillOfContainers: (data: { template: number; shipment: number }) => Promise<any>;
    createBillOfContainersFile: (data: { template: number; shipment: number }) => Promise<PromanFile>;
    createBillOfLading: (data: { template: number; container?: number; shipment?: number }) => Promise<string>;
    createCustomerFile: (data: { template: number; name: string; dateFrom?: dateTime; dateTo?: dateTime; type: string; customer: number; author: number }) => Promise<string>;
    createSupplierFile: (data: { template: number; name: string; dateFrom?: dateTime; dateTo?: dateTime; type: string; supplier: number; author: number }) => Promise<string>;
    documentLogs: (data: { id: number }) => Promise<any[]>;
}
