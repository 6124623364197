import { EntityInterface } from '@proman/services/entity.service';

export const create_online_meeting   = {
    name: 'create_online_meeting',
    params: {
        entityConfig: {
            name: 'create_online_meeting',
            post: [
                'zoomAuthorize',
                'zoomCallback',
                'createTencentEvent',
                'meetAuthorize',
            ],
        }
    }
};

export interface CreateOnlineMeetingInterface extends EntityInterface {
    zoomAuthorize: (data: {
        // personId: number;
        // start: string;
        topic: string;
        // duration: number;
        // participantsId: number[];
        // comments: string;
    }) => Promise<any>;
    meetAuthorize: (data: { start: string; topic: string; duration: number; comments: string; }) => Promise<any>;
}
