import { EntityInterface } from '@proman/services/entity.service';
import { DiscountTag } from '@proman/interfaces/entity-interfaces';

export const discount_tag = {
  name: 'discount_tag',
  params: {
    entityConfig: {
      name: 'discount_tag',
      post: [
        'setArticleCombos',
      ]
    }
  },
}

export interface DiscountTagEntityInterface extends EntityInterface<DiscountTag> {
  setArticleCombos: (data: { id: number, articles: number[] }) => Promise<void>;
}
