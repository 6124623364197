export const cash_register = {
    name: 'account_registry',
    entityConfig: {
        name: 'account_registry'
    },
    params: {
        paymentNamesMap: {
            card: 'by_card',
            cash: 'by_cash',
            D: 'by_coupon',
            credit: 'by_credit',
            advance: 'advance_payment',
            cash_out: 'cash_out',
            cash_in: 'cash_in',
            cash_back: 'cash_back'
        }
    }
};
