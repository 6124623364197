import { EntityInterface } from '@proman/services/entity.service';

export const account_registry = {
    name: 'account_registry',
    params: {
        entityConfig: {
            name: 'account_registry',
            post: ['updateRecord']
        },
    }
};

export interface AccountRegistryEntityInterface extends EntityInterface {
    updateRecord: (data: { id: number; payments: { [key: number]: number } }) => Promise<any>;
}
