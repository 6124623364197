import { clone, deepCopy } from '../utils';

export class QueryBuilder {
    query: any;

    constructor(private Entity: any, private methods: any) {

        this.query = {};

        for (let method in methods) {
            this[method] = this.getMethod(methods[method]);
        }
    }

    getMethod(entityMethod: any) {
        /* eslint-disable */
        const thisRef = this;
        return function(data: any) {
            thisRef.extend(thisRef.query, entityMethod(data));

            return this;
        };
        /* eslint-enable */
    }

    search(extra: any) {
        let data = this.query;

        if (extra) {
            this.extend(data, extra);

        }

        this.query = {};

        return this.Entity.search(data);
    }

    get(extra: any) {
        let data = this.query;

        if (extra) {
            this.extend(data, extra);

        }

        this.query = {};

        return this.Entity.get(data);
    }

    getRequest() {
        // reset current query data when returning request because otherwise
        // it will persist to subsequent unrelated queries
        let request = clone(this.query);

        this.query = {};

        return request;
    }

    sort(sort: string) {
        this.query = { ...deepCopy(this.query), ...{ sort: { name: 'asc' } }, ...{ sort }  };

        return this;
    }

    extend(a: any, b: any) {
        const join = this.mergeJoins(a.join, b.join);

        Object.assign(a, { ...deepCopy(b) });
        a.join = join;

        return a;
    }

    mergeJoins(a: any[], b: any[]) {

        if (!a && !b) {
            return;

        }

        return (a || []).concat(b || []);
    }
}
