export const menuCustomer = [
    {
        name: 'produce',
        state: 'ArticlesExposure',
        acl: 'article.display',
        icon: 'certificate'
    },
    {
        name: 'orders',
        state: 'Orders',
        acl: 'order.display',
        icon: 'tasks'
    },
    {
      name: 'product_library',
      state: 'OrdersProducts',
      acl: 'product.edit',
        showKey: 'productLibraryAvailable',
      icon: 'books',
    },
    {
        name: 'shipments',
        state: 'Shipments',
        acl: 'shipment.display',
        icon: 'truck'
    },
    {
        name: 'development',
        state: 'DevelopmentProjects',
        acl: 'development_project.display',
        icon: 'puzzle-piece'
    },
    {
        name: 'sales_events',
        state: 'SaleEvents',
        acl: 'customer.display',
        icon: 'briefcase'
    },
    {
        name: 'projects',
        state: 'SaleOpportunities',
        icon: 'ticket',
        acl: 'sale_opportunity.display',
    },
    {
        name: 'invoices',
        state: 'Invoices',
        icon: 'file-invoice',
        acl: 'invoice.display',
    },
    {
        name: 'article_tests',
        state: 'ArticlesTests',
        icon: 'clipboard-list-check',
        acl: 'article_test.display',
    },
    {
        name: 'customer_claims',
        state: 'CustomerClaims',
        icon: 'folder-xmark',
        acl: 'customer_claim.display',
    },
    {
        name: 'certificates',
        state: 'CustomersCertificates',
        icon: 'file-certificate',
        acl: 'customer.display',
    },
];
