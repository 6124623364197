export const quant_event = {
  name: 'quant_event',
  params: {
    entityConfig: {
      name: 'quant_event',
      types: [
        'purchase',
        'sale',
        'stock_in',
        'stock_out',
        'reserve_in',
        'reserve_out',
        'to_production',
        'from_production',
        'restock',
        'consumed',
        'write_off',
        'rejected',
        'edit',
        'inventorization',
        'return',
        'damaged',
        'quarantine',
        'return_from_reservation',
        'processing_in',
        'processing_out',
      ]
    }
  }
}
