import { EntityInterface } from '@proman/services/entity.service';

export const payment_provider = {
    name: 'payment_provider',
    params: {
        entityConfig: {
            name: 'payment_provider',
            get: [
                'test',
                'testPay'
            ]
        }
    }
};

export interface PaymentProviderEntityInterface extends EntityInterface {
    test: (data: { order: number}) => Promise<any>;
    testPay: (data: { paymentProvider: 'stripe'; order: number; paymentProviderMethod: 'card'}) => Promise<any>;
}
