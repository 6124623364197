import { EntityInterface } from '../services/entity.service';
import { SmartTagProduct } from '../interfaces/entity-interfaces';

export const smart_tag_product = {
  name: 'smart_tag_product',
  params: {
      entityConfig: {
          name: 'smart_tag_product',
          post: [
            'generateQr',
            'settingsQr',
            'generateFiles',
            'getPath',
          ],
      }
  }
};

export interface SmartTagProductEntityInterface extends EntityInterface<SmartTagProduct> {
    generateQr: (data: {
        data: string,
        errorCorrectionLevel?: 0|1|2|3|4,
        size?: number,
        margin?: number,
        roundBlockSizeMode?: 0|1|2|3,
        logoPath?: string,
        labelText?: string,
        defaultFont?: 0|1,
        labelFontSize?: number,
        labelAlignment: 0|1|2,
        writer: 'eps'|'png'|'svg'|'pdf' }) => Promise<unknown>;

    settingsQr: (data: {
        data: string,
        errorCorrectionLevel?: 0|1|2|3|4,
        size?: number,
        margin?: number,
        roundBlockSizeMode?: 0|1|2|3,
        logoPath?: string,
        labelText?: string,
        defaultFont?: 0|1,
        labelFontSize?: number,
        labelAlignment: 0|1|2,
        writer: 'eps'|'png'|'svg'|'pdf' }) => Promise<unknown>;

    getPath: () => Promise<string>;
    generateFiles: (data: { product: number }) => Promise<unknown>;
}
